.thumbsContainer  {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16;
    justify-content: space-between;
    row-gap: 10px;
   margin-bottom: 10px;
   
  }
  
.thumb {
    display: flex; 
    flex-direction: row;
    width: 256px;
    height: 48px;
    border-radius: 6px;
    border: solid 1px #979797;
    padding: 12px;
   
  }
  
  .thumbInner {
   display: flex;
   flex-direction: row;
   flex: 1;
   align-items: center;
  }
 
  img.preview {   
    width: 36px;
    height: 36px;
    margin-left: -7px;
  }

  .fileUploadContainer {
    width: 100%;
    height: 204px;
    cursor: pointer;
  }
 
  .fileName {
    flex: 2;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .spannote{
    color: black;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
  }
 .message{
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  margin: 0px 0px 8px 0px;
  color: #666666;
 }

 .deleteIcon {  
  cursor: pointer;
 }
 .deleteIcon img {
  height: 25px;
  width: 25px;
 }
  
 .fileSizeError {
  font-family: "VodafoneLt-Regular";
  font-size: 16px;
  color: red;
 }

 .uploadLoader{
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
}
.textstyle{
  color: #BD0000;
}