.calendarContainer {
  position: absolute;
  border-radius: 8px;
  border: 0.4px solid #999999;
  padding: 16px;
  background-color: #FFFFFF;
  right: 0px;
  width: 270px;
  z-index: 1;
  box-shadow: 2px 2px 8px 0px #0000001A;
}

/* .calendarHeader {
  margin: 10px 0;
} */

.monthlabel{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 10px 0; */
}

.calendarNavButton {
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
}

.backarrow{
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.disablearrow{
  width: 21px;
  height: 21px;
  opacity: 0.5;
}

.disabled{
  opacity: 0.5;
}

.activebtn{
  cursor: pointer;
}

.rightarrow{
  transform: rotate(180deg);
}

.calendarYear {
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  align-items: center;
}

.combination{
  border-radius: 6px;
  padding: 12px;
  background-color: #F5F5F5;
  gap: 10px;
}
/* 
.calendarGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 9px;
  border-radius: 6px;
  padding: 12px;
  background-color: #FFFFFF;
  margin-top: 5px;
} */
/* .calendarMonth {
  border-radius: 4px;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 18.37px;
  align-items: center;
  width: 33.79px;
  height: 32.71px;
  color: #333333;
  display: flex;
  justify-content: center;
}

.calendarMonth:hover {
  background-color: #e0e0e0;
}

.calendarMonth.selected {
  background: #007692;
  color: #FFFFFF;
} */

.applyButton {
  width: 100%;
  border-radius: 6px;  
  gap: 10px;
  background-color: #E60000;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  align-items: center;
  color: #FFFFFF;
  padding: 6px;
  border: none;
  margin-top: 10px;
}

/* .applyButton:hover {
  background-color: #c82333;
} */

.customtext{
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 18.37px;
  letter-spacing: 2px;
  color: #333333;
  border-bottom: 0.2px solid #4B4D4F;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.chevronredleft{
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
}

.selectmonth{
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: block;
  margin-bottom: 5px;
}

.radioContainer{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.radiotext{
  position: absolute;
  margin-left: 5px;
  margin-top: 2px;
  font-family:  "VodafoneRg-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 18.37px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* .calendarMonth.rangeSelected {
  background: #A8D2DA;
} */

.calendarGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 9px;
  border-radius: 6px;
  padding: 12px;
  background-color: #FFFFFF;
  margin-top: 5px;
  overflow: hidden;
  position: relative;
}

.calendarMonth {
  border-radius: 4px;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 18.37px;
  align-items: center;
  width: 33.79px;
  height: 32.71px;
  color: #333333;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.calendarMonth:hover {
  background-color: #e0e0e0;
}

.calendarMonth.selected {
  background: #007692;
  color: #FFFFFF;
}

.calendarMonth.rangeSelected {
  background: #A8D2DA;
  color: #333333;
}

/* .calendarMonth.selected:nth-of-type(2)::before{
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -17px;
  right: 32px;
  background: #A8D2DA;
  color: #333333;
  z-index: 0;
} */

.calendarMonth.lastSelected::before {
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -17px;
  right: 32px;
  background: #A8D2DA;
  color: #333333;
  z-index: 0;
}

.calendarMonth.rangeSelected::before {
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -17px;
  right: -17px;
  background: #A8D2DA;
  color: #333333;
  z-index: 0;
}
.calendarMonth.rangeSelected span {
  position: relative;
  z-index: 2;
}

.disableapplybtn{
  opacity: 0.5;
}