.label {
    font-family: "VodafoneRg-Regular";    
    /* font-size: 14px; */
    font-size: 18px;
    color: #666666;
    font-weight: 400;
    padding-bottom: 5px;

}
.value {
    font-family: "VodafoneRg-Regular";
    margin-bottom: 10px;
    color: #717476;
}

.error {
    color: #CD3500;
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
}