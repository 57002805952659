.conatiner{
    display: flex;
    flex-direction: row;
    height: 44px;/* height: 56px; */
}

.item{
   font-family: "VodafoneLt-Regular";
   font-size: 18px;
   align-self: center;
   cursor: pointer;
}
.img{
    width: 24px;
    height: 24px;
    margin-left: 8px;
    margin-right: 8px;
}