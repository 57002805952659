/* Footer.css */

.footer {
    /* position: fixed;
    bottom: 0; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 100%; */
    flex: 1;
    height: 56px; /* height: 80px; */
    background-color:  #333333;
    color:  #FFFFFF;    
    padding: 0 15px;
    box-sizing: border-box;
}

.leftside {
    display: flex;
    gap: 10px;
}
.element{
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    text-decoration: none;
    color: #FFFFFF;
    /* border-right: 1px solid #FFFFFF;
    padding-right: 10px; */
}
.rightside {
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
}