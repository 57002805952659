.alertmessage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .alertcontent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width:  500px;
    /* height: 150px; */

  }
  .errormsg{
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    text-align: start;
    margin-bottom: 10px;
  }
  .buttonscan{
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    background-color: #E60000;
    border: 1px solid #979797;
    border-radius: 6px;
    width: 140px;
    height: 42px;
    cursor: pointer;
     }
  .buttonok{
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .errormsghead{
    font-family: "VodafoneRg-Regular";
    font-size: 24px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 10px;
    text-align: start;
  }
  .close{
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
  .closes{
    display: flex;
    justify-content: flex-end;
    align-items: end;
  }