/*CSS*/
.headContainer {
    display: flex;
    /* justify-content: space-between; */
    gap: 10px;
    background-color: #f5f5f5;
  }
  
  .vfuser {
    font-family: "VodafoneRg-Regular";
    font-size: 24px;
    font-weight: 400;
    color: #333333;
    align-items: center;
  }
  
  .addUserBtn {
    width: 221px;
    height: 48px;
    /* padding: 12px 20px 12px 20px; */
    font-size: 20px;
    border-radius: 6px;
    margin-top: 24px;
    color: #ffffff;
    border: 1px solid #999999;
    font-family: "VodafoneRg-Regular";
    background-color: #666666;
  }
  .CustomerContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 20px;
    min-height: 95px;
    overflow-x: hidden;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #979797;
    border-radius: 6px;
  }
  
  .expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column: span 5 / span 5;
    gap: 10px;
  }
  
  .CustomerList {
    flex: 1;
    color: #000000;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
  }
  .extendbtn{
    height: 48px;
    gap: 10px;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    font-family: "VodafoneRg-Regular";
    background-color: #E60000;
    height: 44px;
    border: none;
    width: 228px;
    cursor: pointer;
  }
  .btnLoaderContainer{
    width: 148px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
   
  .btnLoader{
    width: 44px;
    height: 44px;
    align-items: center;
    justify-content: center;
  }
  .spanlabel {
    color: #666666;
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
  }
  .CustomerList p {
    color: #000000;
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
  }
  /* switch */
  .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  }
  .active{
    padding-top: 20px;
  }
  .CustomerContainers {
    /* display: grid;
    grid-template-columns: repeat(4, 1fr); */
    
    margin-top: 20px;
    min-height: 95px;
    overflow-x: hidden;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #979797;
    border-radius: 6px;
  }
  .CustomerContainer {
    display: grid;
   grid-template-columns: repeat(4, 1fr); 
   
   margin-top: 20px;
   min-height: 95px;
   overflow-x: hidden;
   padding: 20px;
   background-color: #ffffff;
   border: 1px solid #979797;
   border-radius: 6px;
 }
 .requestDetail{
   background-color: #EBEBEB;
   padding: 3px 6px;
   margin: 5px 0px;
   border-radius: 4px;
   color: #333;
   font-family: "VodafoneRg-Bold";
   font-size: 14px;
 }
 .CustomerList p {
  color: #000000;
  font-size: 18px;
  font-family: "VodafoneRg-Regular";
}
.spanlabel {
  color: #666666;
  font-size: 18px;
  font-family: "VodafoneRg-Regular";
}
.CustomerList {
  flex: 1;
  max-width: 20%;
  min-width: 20%;
  color: #000000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
}
 .Customerbox{
  display: flex;
  margin-top: 10px;
}
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7e7e7e;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  
    /* content: "\2713"; */
  }
  
  input:checked + .slider {
    background-color: #00697c;
    cursor: not-allowed;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
    /* content: "\2713"; */
  }
  
  /* Rounded sliders */
  .slider {
    border-radius: 34px;
  }
  
  .slider:before {
    border-radius: 50%;
  }
  .spanSwitch {
    margin: 0px 5px;
  }
  /* SearchList */
  .searchBar {
    margin-bottom: 0px;
    margin-top: 0%;
  }
  
  .searchList {
    width: 340px;
  }
  .emptyContainer{
    display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 25px;
      min-height: 95px;
      overflow-x: hidden;
      border-radius: 6px;
      border: 1px solid #979797;
      padding: 20px;
      gap: 16px;
      background-color: white;
  }
  
  .container {
    overflow-x: hidden;
    border-radius: 6px;
    border: 1px solid #979797; /* 1px border */
    padding: 20px;
    gap: 16px; /* Gap between flex items */
    background-color: white;
  }
  .headData {
    display: flex;
    flex-direction: column;
  }
  
  .addButton {
    width: 186px;
    height: 48px;
    border-radius: 6px;
    border: 1px solid #999999;
    font-family: "VodafoneRg-Regular";
    background-color: #666666;
    color: #ffffff;
    padding: 20px, 20px, 20px, 20px;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 32px;
  }
  .text {
    padding-top: 10px;
    font-family: "VodafoneRg-Regular";
    color: #333333;
    font-weight: 700;
  }
  .textuser {
    font-family: "VodafoneRg-Regular";
    color: #333333;
    font-weight: 700;
    margin-top: 32px;
    margin-bottom: 20px;
    padding-top: 10px;
    font-size: 20px;
  }
  .textCustomer {
    font-family: "VodafoneRg-Regular";
    color: #333333;
    font-weight: 700;
    margin-top: 10px;
    height:24px;
  }
  .headnote{
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    color: #333333;
    background-color: #FFEBCD;
    border-radius: 6px;
    height: 32px;
    display: flex; 
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.infocircle{
    height: 20px;
    width: 20px;
    margin: 0px 5px 0px 10px;
}
  .customer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0px 0px 0px;
  }
  .icon {
    cursor: pointer;
    height: 18px;
    width: 18px;
  }
  
  .detailsContainer {
    display: flex;
    flex-direction: column;
  }
  
  .detailsContainer[data-vf-user="true"] {
    flex-direction: column-reverse;
  }
  
  .transparentBtn {
    background-color: transparent;
    display: flex;
    gap: 10px;
    align-items: flex-start;
    border: none;
  }
  
  .moduleAssignedHeader {
    font-family: "VodafoneRg-Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }
  .expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column: span 5 / span 5;
    gap: 10px;
  }
  .transparentBtn {
    background-color: transparent;
    display: flex;
    gap: 10px;
    align-items: flex-start;
    border: none;
  }
  .radioField {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  .radioInput {
    width: 20px;
    height: 20px;
    accent-color: #00697c;
  }
  .radioLabel {
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 16px;
    color: #0d0d0d;
  }
  
  .sectionContainer{
    display: flex;
    margin-bottom: 16px;
  }
  .sectionTitle{
    font-family: "VodafoneRg-Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-right: 16px;
  }
  .Icon{
    height: 19px;
    width: 19px;
    cursor: pointer;
  }
  .loaderContainer{
    width: 20px;
    height: 20px;
  }
  .sharedEmailContainer {
    display: flex;
    flex:1;
    margin-top: 20px;
    min-height: 95px;
    overflow-x: hidden;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #979797;
    border-radius: 6px;
  }
  
  .borderbottom{
    border-bottom: 2px solid #E6E6E6;
  }

  .raisedreq{
    font-family: "VodafoneRg-Regular";
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
  }