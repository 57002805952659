/*CSS*/
/* .addButton {
    width: 221px;
    height: 48px;
    font-size: 20px;
    border-radius: 6px;
    margin-top: 24px;
    color: #ffffff;
    border: 1px solid #999999;
    font-family: "VodafoneRg-Regular";
    background-color: #666666;
  } */
  .fieldContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .editrole{
    height: 20px;
    cursor: pointer;
    width: 20px;
  }
  .sidebar{
    display: flex;

  }
  .sectionContainer{
    display: flex;
    margin-bottom: 16px;
    margin-top: 30px;
  }
  .input {
    width: "auto";
    margin-bottom: 0px;
  }
  
  .inputError,
  .selectError {
    border-color: #cd3500 !important;
  }
  
  .label {
    font-family: "VodafoneRg-Regular";
    margin-bottom: 10px;
    color: #100045;
  }
  
  .checkboxaligngrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    gap: 16px;
    padding: 0px !important;
  }
  
  .checkboxaligngrid .CheckBox:nth-child(n + 4) {
    grid-column: 2;
  }
  
  .buttonContainer {
    display: flex;
    padding: 18.5px 16px;
  }
  
  .backButton {
    background-color: #666666;
  }
  .labelcheckbox {
    margin-bottom: 10px;
    color: #333333;
    font-family: "VodafoneRg-Regular";
  }
  .labelcheckboxgrix {
    margin-bottom: 10px;
    color: #333333;
    text-align: center;
    margin-left: 35%;
  
    font-family: "VodafoneRg-Regular";
  }
  
  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: calc(100vh - 88px - 21px); */
  }
  .loaderContainer{
    width: 25px;
    height: 25px;
  }
  .Icon{
    height: 20px;
    cursor: pointer;
    width: 20px;
    padding-bottom: 2px;
  }
  .sectionTitle{
    font-family: "VodafoneRg-Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin-left: 14px;
    margin-bottom: 20px;
    line-height: 24px;
    color: #333333;
    margin-right: 16px;
  }
  .form fieldset {
    padding: 6px 14px 0 14px;
  }
  
  .assign {
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
  }
  
  .radioGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 24px;
  }
  
  .radioInput {
    width: 20px;
    height: 20px;
    accent-color: #00697c;
  }
  
  .radioField {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  
  .radioLabel {
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 18px;
    color: #0d0d0d;
  }
  
  .separator {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-top: 10px;
    border-color: #666;
    transform: scaleX(1.5);
    opacity: 100;
  }
  

