.container {
    background-color: #333333;
    border-radius: 8px;   
    min-height: 62px;
    padding: 16px 24px 16px 24px;
    position: fixed;
    bottom: 10%;
    min-width: 590px;   
    
    max-width: 700px;   
    left:50%;
   margin-left:-300px;
   
    
}
.form {
  background-color: #333333;
  border-radius: 8px;   
  min-height: 62px;
  padding: 16px 24px 16px 24px;
  position: fixed;
  bottom: 16%;
  min-width: 590px;   
  max-width: 700px;   
  right: 0;
  margin-right:10%;   
}
.animateToast {  
  animation-name: slideUp;
  animation-duration: 2s;
  animation-timing-function: ease-in;
  display: block;
}

.subcontainer {
    display: flex;
    justify-content: center;
}
.imageIcon{
    height: 30px;
    width: 30px;;
}

.subcontainerleft {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    color: #FFFFFF;
    font-family: "VodafoneRg-Regular";
    align-items: center;
    font-size: 22px;
}  
@keyframes slideUp {
  0%,
  50% {
    transform: translateY(100%);   
    opacity: 0;   
  }
   
  60%,
  100% {
    transform: translateY(0);   
    opacity: 1;  
  }
}