.formInput {
    background-color: #fff;
    height: 48px;
    border-Radius: 5px;
    /* max-width: 500px; */
    margin-top: 5px;
    gap:5px;
    border: 1px solid #979797;
    padding-left: 10px;
    color: #333333;
    font-family: "VodafoneRg-Regular";
    -webkit-box-sizing: border-box; /* prevent additonal 6px in width /height */
    -moz-box-sizing: border-box; /* prevent additonal 6px in width /height */
    box-sizing: border-box; /* prevent additonal 6px in width /height */
    font-size: 20px;
}
.formInput:disabled{
    background-color: #CCCCCC;
    cursor: not-allowed;
}

.textArea {
    background-color: #fff;
    min-height: 119px;
font-size: 20px;
    
    border-radius: 6px;
    border-width: 1px;
    border-color: #C5C1C8;
    border-style: solid;
    border: 1px solid #979797;
    padding: 12px;
    font-family: "VodafoneRg-Regular";
    color: #333333;
    -webkit-box-sizing: border-box; /* prevent additonal 6px in width /height */
    -moz-box-sizing: border-box; /* prevent additonal 6px in width /height */
    box-sizing: border-box; /* prevent additonal 6px in width /height */
}