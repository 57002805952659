.maincontainer{
  background-color:#F4F4F4;
  padding: 20px;
}
.subcontainer{
  background-color:#fff;
  border-radius: 6px;
 border: 1px solid  #979797;
}
.container{
  width: 570px;
  margin: 0 auto;
}
.title{
  padding:24px 24px 20px 24px;
  color:  #333333;
  font-family: "VodafoneRg-Regular";
font-size: 28px;
font-weight: 700;
line-height: 24px;
text-align: left;

}
.fieldContainer {
    display: flex;
    flex: 1;
    flex-direction: column; 
    padding:0px 24px 5px 24px;
  }
  .textarea{
  height: 119px;
  width: 100%;
  font-family: "VodafoneLt-Regular";
  color: #100045;
  border-radius: 6px;
  padding-left: 10px;
  }
  .commentContainer{
    padding:0px 24px 20px 24px;
  }
  .attachment{
    background-color: #fff;
    height: 140px;
    border-Radius: 5px;
    margin-top: 5px;
    gap: 5px;
    border: 1px solid #979797;
    padding-left: 190px;
    padding-top: 55px;
    color: #100045;
    font-family: "VodafoneLt-Regular";
    box-sizing: border-box;
  }
  .button{
    /* margin-right: 188px; */
    margin: auto;
    padding:0px 24px 20px 24px;
  }
  .submit{ 
    margin: auto;
  }
  .loader{
    margin: auto;
  }

  .inputError,
  .selectError {
    border-color: #CD3500 !important
  }
  .separator {
    margin-top: 16px;
    margin-bottom: 16px;
    border-color: 1px solid #666666
  }
  .commentValidation {
    color:#666666;
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    margin-right: 26px;
    text-align: right;
  }