.serviceListContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.serviceDetailContainer {
    display: flex;
    padding: 10px;  /* padding: 20px 30px; */
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;  /* gap: 16px; */
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid #979797;
    background: #FFF;
    /* cursor: pointer; */
}

.serviceHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    gap:78px;
    padding-bottom: 10px;
    border-bottom: 2px solid #E6E6E6;
}


.createdDate {
    color: var(--Text-Primary, #0D0D0D);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
}

.itemHeader {
    width: 100%;
    float: left;
}

.serviceFooter {
    display: flex;
    /* align-items: center; */
    gap: 24px;
    align-self: stretch;
}

.statusContainer {
    display: flex;
    padding: 2px 16px;
    /* width: 82px; */
    height: 26px;
    margin: auto;
    gap: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: #333;
    font-size: 14px;
    font-family: "VodafoneRg-Bold";
    /* font-weight: 700; */
    line-height: 16.04px;
    box-sizing: content-box;
    /* padding: 0px 10px; */
    /* width: 160px;
    height: 42px; */
    /* border-radius: 6px 6px 0px 0px; */
    /* font-size: 16px; */
}

.serviceFooter>div {
    color: #666;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-style: normal;
    line-height: 28px;
    padding-right: 10px;
    word-wrap: break-word;
    border-right: 2px solid #EBEBEB;   
}

.serviceFooter > div:last-child {   
    border: none !important;    
}

.serviceFooter > div > span:nth-child(2n+1) {font-family: "VodafoneRg-Regular";font-size: 16px; color:#666666; font-weight: 400;}
.serviceFooter> div > span:nth-child(2n) { font-family: "VodafoneRg-Regular";font-size: 16px; color:#333333; font-weight: 700; }

.serviceName {
    color: #E60000;
    border-bottom: 1px solid #E60000;
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
    font-style: normal;
    /* font-weight: 700; */
    line-height: 34px;
    flex: 1;
    cursor: pointer;
    margin-right: 10px;
}

.submittedOn {
    color: #666;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    flex: 1
}

.descriptionContainer {
    color: #333333;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 1084px;
}

.serviceMiddleDiv {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}
.iconsContainer {
    display: flex;
}
.companyDiv {
    width: 25%;
}
.requestId {
    width: 20%; 
}
.location {
    width: 17%;
}
.country {
    width: 19%;
}
.requestor {
    width: 34%;
}
.received{
    background-color: #FFF1BB;
    border: 2px solid #F4D55B
}
.in-progress{
    background-color: #FFE1AB;
    border: 2px solid #F4B035
}
.pending-customer-action{
    background-color: #D5F9FF;
    border: 2px solid #89DBE9
}
.completed{
    background-color: #C7FFE6;
    border: 2px solid #80D8B0
}
.on-hold{
    background-color: #D4D4D4;
    border: 2px solid #9B9191
}
.closed{
    background-color: #E8E8E8;
    border: 2px solid #C2C2C2

}

