.bannerContainer {
    display: flex;
    /*flex: 1;*/
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.text {
    position: absolute;
    color: #FFFFFF;
    font-family: "VodafoneLt-Regular";
    font-size: 34px;  /* font-size: 64px; */
    font-weight: 400;
  }
.image{
    width: 100%;
    height: 120px;/* height: 192px; */
}