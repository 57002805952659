/*CSS*/

.listContainer {
    display: flex;
    flex: 1;
    align-self: stretch;    
    margin-top: 30px;
}
.servicesList {
    display: flex;
    flex: 1;
    flex-direction: column;
    list-style: none;
    justify-content: center;
    padding-left:0px;
    margin-bottom: 0px;
}
.servicesList > li {
    cursor: pointer;
    margin-bottom: 24px;
}
.commentBox img{
    width: 36px;
     height: 36px;
     cursor: pointer;

}
.hideList {display: none;}
.header{
     /* border-bottom: 1px solid #E60000; */
     display: flex;
      }
.headeright{
    display: flex;
    /* gap: 70px; */
}
.commentIcon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-left:10px;
}
.ammendServiceIcon {
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-left:10px;
}
/* searchbar */

.mainContainer {
   /* display: flex;
    align-items: end;
    vertical-align: baseline;*/
    display: flex;
    justify-content: space-between;
    align-items: end;
}
.labelor {
    margin: 35px 20px 10px 20px;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
}
.labelstatus {
    margin-bottom: 2px;
    font-family: "VodafoneRg-Regular";
    color: #666666;
    font-size: 14px;
}
.inputContainer{
    width: 345px;
}
.listCardMmargin{
    /* cursor: pointer; */
    margin-bottom: 30px;
}
.searchContainer { 
    /*flex: 8;*/
}
.filterContainer {
   /* flex: 1;*/
}

.formikBtnContainer {
    display: flex;
    justify-content: space-between;
   /* padding: 5px; */
    gap:10px;
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    font-weight: 700;
}

.formikBtnClear{
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    flex: 1;
    height: 40px;
    border : solid 1px #999999;
    background-color: #FFFFFF;
}

.formikBtnSubmit {
    color: #fff;
    background-color: rgb(32, 19, 19);
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    flex: 1;
    height: 40px;
}
.dropdownWidth {
    width: auto !important;
}
.selectedFilters{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.selectedItem{
    background: #EBEBEB;
    border-radius: 26px;
    border: 1px solid #979797;
    margin-right: 16px;
    height: 36px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 8px 12px 8px 12px;
    margin-bottom: 16px;
}
.label{
    font-family: "VodafoneRg-Bold";
    font-size: 14px;
    margin-right: 8px;
}
.value{
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
}
.selectedFilterContainer {
    display: flex;
    margin-top: 20px;
}
.fieldContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
  
  .inputError,
  .selectError {
    border-color: #CD3500 !important
  }  

  .applyFilterBtn {
    height: 48px !important;
    margin-top: 2px;
    width: 127px;
  
  }
  .labelShowFilter {
    color: #000000;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    margin-top:4px;
  }
  
.download {
    cursor: pointer;
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #999999;
    border-radius: 6px;
    font-size: 14px;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    height: 48px !important;
    width: 127px;
  }

  
.filterContainer {
    display: flex;
    justify-content: end;
    flex: 1;
    margin-right: 10px;
}
.downloadlogo{
    margin-left: 10px;
    margin-bottom: 5px;
    height: 20px;
    width: 20px;
}

.videomain{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.video{
    width: 100%;
    height: 250px;
}
.moduledescription{
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    color: #333333;
    background-color: #DCDCDC;
    /* height: 36px; */
    padding: 8px 8px;
    display: flex;
    align-items: center;
    border-radius: 6px;
  }

  .marginfromtop{
    margin-top: 24px;
  }
  
  .descriptioncircle{
    padding: 0px 5px;
  }
