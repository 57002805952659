.container{
     border-radius: 6px;
     border: 1px solid #979797;
     background-color: #FFFFFF;
     overflow-x: hidden;    
     padding: 10px;
     gap: 16px;
     margin-bottom: 20px;
     margin-top: 20px;
}
.searchhead{
    display: flex;
    justify-content: space-between;
}
.listContent{
    display: flex;
    flex-direction: row;
}
.list{
    flex: 1;
}
.head{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.subhead{
    display: flex;
    
}
.orgname{
    border-bottom: 1px solid #e60000;
    color: #e60000;
    cursor: pointer;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
}
.status{
    border-radius: 30px;
    color: #fff;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    /* margin-left: 10px; */
    /* margin-top: 8px; */
    margin: auto 8px;
    padding: 0px 16px;
    height: 24px !important;
}
 /* status background color */
  .pending{
    background-color: #EB9700;
  }
  .approved{
    background-color: #009900;
  }
  .rejected{
    background-color: #BD0000;
  }
.gotologo{
    height: 28px;
    width: 28px;
    margin-top: 5px;
    cursor: pointer;
}
.label{
    color: #666666;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
}
.value{
    color: #000000;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 700;
}
.duedate{
    color: #333333;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    background-color: #FFEECC;
    border-radius: 4px;
    margin-top: 20px;
    padding: 2px 5px;
}
.search{
    margin-top: -25px;
    }

    .headnote{
        font-family: "VodafoneRg-Regular";
        font-size: 14px;
        color: #333333;
        background-color: #FFEBCD;
        border-radius: 6px;
        height: 32px;
        display: flex; 
        align-items: center;
        margin-bottom: 20px;
    }
    .infocircle{
        height: 20px;
        width: 20px;
        margin: 0px 5px 0px 10px;
    }

.Emptydiv{
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    background-color: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #979797;
    display: flex;
    margin-top: 10px;
}