.buttonPrimary {
    background-color: #efefef;
    height: 40px;
    /* max-width: 500px; */
    border-radius: 5px;
    border-width: 1px;
    margin-top: 5px;
    gap:5px;
    border-color: #C5C1C8;
    border-style: solid;
    padding: 0px 10px 0px 10px;
    color: #100045;
    font-family: "VodafoneRg-Regular";
    -webkit-box-sizing: border-box; /* prevent additonal 6px in width /height */
    -moz-box-sizing: border-box; /* prevent additonal 6px in width /height */
    box-sizing: border-box; /* prevent additonal 6px in width /height */
}

.submit {
    display: flex;
    background-color: #E60000;
    color: #fff;
    border-radius: 6px;
    height: 48px;
    padding: 12px 16px 12px 16px;
    border-width: 0px;
    width: 148px;
    justify-content: center;
    align-items: center;    
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    cursor: pointer;
    margin: auto 0 auto auto;
}
.submit:disabled{
    /* background-color: gray; */
    opacity: 0.5;
    cursor: not-allowed;
}

.backButton {
    display: flex;
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 6px;
    height: 48px;
    border: 1px solid #999999;
    width: 148px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 12px 16px 12px 16px;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    cursor: pointer;
    margin: auto auto auto 0px;
}
.btnLoader{
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
}
.btnLoaderContainer{
    width: 148px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.linkButton {
    cursor: pointer;
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    text-decoration: underline;
  }