/*CSS*/

.addButton {
    width: 226px;
    height: 48px;
    font-size: 20px;
    border-radius: 6px;
    margin-top: 24px;
    color: #ffffff;
    border:none;
    font-family: "VodafoneRg-Regular";
    background-color: #E60000;
  }
  .addButtons {
    display: flex;
 width: 320px;
 margin-left: 30px;
 padding-left: 10px;
 justify-content: space-between;
   }
   .editrole{
    height: 20px;
    cursor: pointer;
    width: 20px;
  }
  .customStyles{
    width: 100%;
  }
  .sideNavStyle{
    width: 60% !important;
    overflow: hidden;
    }
  .obj{
    display: contents;
  }
  .textfield{
    width: 100%;
  }
   .btndiv{
     
  /* flex: 1; */
  display: flex;
  align-items: center;
 /* justify-content: center; */
   }
   .btndivs{
    display: flex;
    align-items: center;
    margin-right: 40px;
     }
  .fieldContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 24px;
  }
  
  .input {
    width: "auto";
    margin-bottom: 0px;
  }
  
  .inputError,
  .selectError {
    border-color: #cd3500 !important;
  }
  
  .label {
    font-family: "VodafoneRg-Regular";
    margin-bottom: 10px;
    color: #100045;
  }
  
  .checkboxaligngrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    gap: 16px;
    padding: 0px !important;
  }
  
  .checkboxaligngrid .CheckBox:nth-child(n + 4) {
    grid-column: 2;
  }
  
  .buttonContainer {
    display: flex;
    padding: 18.5px 16px;
  }
  
  .backButton {
    background-color: #666666;
  }
  .labelcheckbox {
    margin-bottom: 10px;
    color: #333333;
    font-family: "VodafoneRg-Regular";
  }
  .labelcheckboxgrix {
    margin-bottom: 10px;
    color: #333333;
    text-align: center;
    margin-left: 35%;
  
    font-family: "VodafoneRg-Regular";
  }
  
  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: calc(100vh - 88px - 21px); */
  }
  
  .form fieldset {
    padding: 6px 14px 0 14px;
    height: calc(86vh - 88px - 21px); 
  }
  
  .assign {
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
  }
  
  .radioGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 24px;
  }
  
  .radioInput {
    width: 20px;
    height: 20px;
    accent-color: #00697c;
  }
  
  .radioField {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  
  .radioLabel {
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 16px;
    color: #0d0d0d;
  }
  
  .separator {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-top: 10px;
    border-color: #666;
    transform: scaleX(1.5);
    opacity: 100;
  }

.inputError,
.selectError {
  border-color: #CD3500 !important
}
.expanded {
  display: flex;
  flex-direction: column;
  align-items: flex-end ;

  gap: 10px;
}
.transparentBtn {
  background-color: transparent;
  display: flex;
  gap: 10px;

  border: none;
}
.discription{
  font-family: "VodafoneRg-Regular";
  border-radius: 6px;
  border: 1px solid rgb(0,0,0,0.2);
  background-color: #f4f4f4;
  min-width: 500px;    
}
.gridcontainer {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap:20px;
  
  width: 100%;
  padding: 10px;
}
.griditem {
  color: #333333;
  background-color: #ffffff;
  border:0.4px solid #979797;
  border-left: 6px solid #007C92;
  border-radius: 6px;
  padding: 8px;
  white-space: normal;
  word-break: break-word;
}
.userrole{
  color: #333333;
  font-family: "VodafoneRg-Regular";
  font-size: 20px;
  padding-left: 10px;
  padding-top: 5px;
  font-weight: 700;
}
.rolename{
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}
.roleinfo{
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  color: #333333;
}
.formscroll{
  overflow-y: auto;
  overflow-x: hidden;
}

.permission{
  color: #CD3500;
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  display: block;
  margin-top: 10px;
  /* text-align: center; */
}