.fieldContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.inputError,
.selectError {
  border-color: #CD3500 !important
}

.label {
  font-family: "VodafoneRg-Regular";
  margin-bottom: 10px;
  color: #100045;
}
.radioLabel{
  margin-left: 8px;
}

.headerContainer {
  display: flex;
  height: 48px;
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 16px;
}

.headerText {
  flex: 1;
  height: 48px;
  font-family: "VodafoneRg-Bold";
  align-items: center;
  justify-content: center
}

.granularityContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

}
.granularityOptionContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}


.sectionTitle {
  flex: 1;
  font-family: "VodafoneRg-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 16px;
}

.granularityOption {
  flex: 1;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}
.granularityOption>input {
margin-right: 8px;
}



.granularityOptionContainer>div {
  flex: 50%;
  margin-bottom: 8px;
}

.emptyContainer{
  display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    min-height: 95px;
    overflow-x: hidden;
    border-radius: 6px;
    border: 1px solid #979797;
    padding: 20px;
    gap: 16px;
    background-color: white;
}