.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modalContent {
    background-color: #FFFFFF;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    height: 300px;
    /* width: 475px; */
  }
  .message{
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-weight: 400;
    color: #333333; 
    text-align: start;
  }
  .close{
    height: 22px;
    width: 22px;
    cursor: pointer;
  }
  .closes{
    display: flex;
    justify-content: flex-end;
    align-items: end;
  }
  .buttonscan{
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #979797;
    border-radius: 6px;
    width: 140px;
    height: 42px;
    cursor: pointer;
  }
  .buttonssub{
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    background-color: #E60000;
    border: 1px solid #979797;
    border-radius: 6px;
    width: 140px;
    height: 42px;
    cursor: pointer;
  }
  .notvalid{
    opacity: 0.5;
    cursor: not-allowed;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    background-color: #E60000;
    border: 1px solid #979797;
    border-radius: 6px;
    width: 140px;
    height: 42px;
  }
  .input{
    border: 1px solid #979797;
    border-radius: 6px;
    height: 48px;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-weight: 400;
    color: #333333;
  }
  .modalContent input {
    margin: 10px 0;
    padding: 10px;
    width: 100%;
  }
  .buttons{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
.head{
  text-align: start;
  font-family: "VodafoneRg-Regular";
  font-size: 24px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 10px;
}
.label{
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  text-align: start;
}