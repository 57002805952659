.overlayInnerbox {
    position: absolute;
    right: 20px;
    z-index: 2;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin-top: 10px;
    min-width: 363px;
    max-width: 450px;
    /* height: 211px; */
    padding-right: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.headerContainer{
    display: flex;
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 16px;
}

.header {
    flex: 1;
    font-family: "VodafoneRg-Bold";
    font-size: 16px;
    height: 48px;
    line-height: 48px;
}
.close{
    align-content: flex-end;
    align-self: center;
    cursor: pointer;
}

.applybtn {
    cursor: pointer;
    border: none;
    background-color: #FFFFFF; 
}

.userIcon {
   height: 36px;
    width: 36px;
    border-radius: 25px;
    background-color: #007C92;
    margin-left: 10px; 
    color: #FFFFFF;
    font-size: 16px;
    text-align: center;
    line-height: 36px;
    font-family: "VodafoneRg-Regular"; 
}
