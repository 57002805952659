 .tabContainer {
    display: flex;
    height: 56px;
    flex-direction: row;
    justify-content: left;
    background-color: #fff;
    margin-bottom: 24px;
 }
 .tabContainer > label {
    flex:1;
    font-size: 16px;
    font-family: "VodafoneLt-Regular";
 }
 .serviceTabContainer {
   display: flex;
    height: 20px;
    flex-direction: row;
    justify-content: left;
    margin-top:-11px;   
    margin-bottom: 28px;
 }
.radioDiv {
   flex: 1;
   justify-content: left;   
}
.radioContainer > input {
  vertical-align: middle;
  margin-top: -1px;
}
.radioContainer > label {
   flex:1;
   font-size: 20px;
   font-family: "VodafoneRg-Regular";
   font-weight: 400;
   color: #0D0D0D;
}
.radioContainer {
   flex: 1;
}
label.blurRadio {
   color: #CCCCCC;
 }
 .serviceRadio {
   margin-right: 8px;
   margin-bottom: 3px;
   vertical-align: middle;
 }

 input[type=radio]{
   height: 20px;
   width: 20px;
   accent-color: #00697C;
 }