.container{
    display: flex;
    background-color: #fff;
    margin-bottom: 16px;
    flex-direction: column;
    padding: 16px 16px;
}
.subContainer{
    display: flex;
    flex-direction: row;
    border: 1px solid #979797;
    height: 500px;
    padding: 16px 16px;
}
.sectionContainer{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.sectionTitle{
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
}
.editIcon{
    height: 19px;
    width: 19px;
    cursor: pointer;
}

.headerContainer{
    display: flex;
    flex-direction: row;
}

.commonFilter{
    display: flex;
    background-color: #fff;
    margin-bottom: 16px;
    padding: 16px 16px 0px 16px;
    justify-content: space-between;
}
.selectedFilters{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.selectedItem{
    background: #EBEBEB;
    border-radius: 26px;
    border: 1px solid #979797;
    margin-right: 16px;
    height: 36px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 8px 12px 8px 12px;
    margin-bottom: 16px;
}
.label{
    font-family: "VodafoneRg-Bold";
    font-size: 14px;
    margin-right: 8px;
}
.value{
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
}
.title{
    font-family: "VodafoneRg-Bold";
    font-size: 18px;
    flex: 1;
    margin-bottom: 16px;
}

.filterContainer{
    flex: 1;
}

.graphContainer{
    flex: 1;
}
.readonlyContainer{
    display: flex;
    margin-bottom: 16px;
}

.readonlyItem{
    flex:1;
    flex-direction: column;
}
.readonlyValue{
    font-family: "VodafoneRg-Bold";
    font-size: 16px;
}

.formikBtnContainer {
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

.formikBtnClear{
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    flex: 1;
}

.formikBtnSubmit {
    color: #fff;
    background-color: rgb(82, 81, 81);
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    flex: 1;
}

.noSelectionMessage{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-family: "VodafoneRg-Regular";
    font-size: 24px;
}
