.addButton{
    background-color: transparent;
    color: #FFFFFF;
    border: 2px solid #ffffff;
    Width:223px;
    Height:40px;
    border-radius: 6px;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    /* Padding:12px 16px 12px 16px; */
    cursor: pointer; 
}

  .previewText{
    flex:1;
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
    font-weight: 400;
    color: #333333; 
}
.sideNavStyle{
  width: 60% !important;
}
.incidentSideNav{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 88px - 40px);
}

.open {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  color: #e60000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  text-decoration: underline;
}

.arrowdown{
  transform: rotate(180deg);
  height: 20px;
}