.tooltipBody{
    background: #fff;
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    color: #000000;
    /* max-width: 200px; */
    padding: 8px 8px;
    border-radius: 8px;
    border: 2px solid #E6E6E6;
}
.popoversecondary::before {
    content: '' !important;
    position: absolute !important;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 10px solid #E6E6E6 !important;
    left: 50% !important;
    z-index: -1 !important;
    transform: translateX(-55%) !important;
  }
  
  .popoversecondary::after {
    content: '' !important;
    position: absolute !important;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 10px solid #E6E6E6 !important;
    left: 50% !important;
    z-index: -1 !important;
    transform: translateX(-45%) !important;
  }
  .saveImg{
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-left: 5px;
  }