.collapsibleContainer {
  border: 1px solid #979797;
  border-radius: 6px;
  padding: 10px;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.collapsible {
  background-color: #ffffff;
  border-radius: 6px;
  color: rgb(0, 0, 0);
}

.content {
  border-radius: 6px;
  display: none;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  transition: max-height 0.3s ease-in-out;
}

.content.active {
  display: block;
}

.open {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  color: #666666;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
}

.close {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  color: #666666;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
}

.arrowdown {
  position: absolute;
  top: 1px;
  left: 80px;
}

.arrowup {
  position: absolute;
  left: 70px;
  top: 1px;
}

.contentInner {
  display: flex;
}

.titleContainer {
  display: flex;
  margin-bottom: 10px;
  margin-top: 5px;
 /* margin-bottom: 20px; */
}

.contentInner {
  display: flex;
}
.liveDetailsDiv {

  color: #666666;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  border-right: 1px solid #e6e6e6;
  
}
.liveDetailsDiv1{
  color: #666666;
  font-size: 16px;
  margin-right: 10px;
  font-family: "VodafoneRg-Regular";
  border-right: 1px solid #e6e6e6;
flex: 1.6;
}
.liveDetailsDiv2{
  color: #666666;
  font-size: 16px;
  margin-right: 8px;
  font-family: "VodafoneRg-Regular";
  border-right: 1px solid #e6e6e6;
flex: 1;
}
.liveDetailsDiv3{
  color: #666666;
  font-size: 16px;
  margin-right: 8px;
  font-family: "VodafoneRg-Regular";
  border-right: 1px solid #e6e6e6;
  flex: 1;
}
.liveDetailsDiv4{
  color: #666666;
  font-size: 16px;
  margin-right: 8px;
  font-family: "VodafoneRg-Regular";
  border-right: 1px solid #e6e6e6;
  flex: 1.5;
}
.liveDetailsDiv5{
  color: #666666;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  flex: 1.5;
}
.listdiv{
  display: flex;

}

.liveDetailsDiv:last-child {
  border-right: none;
}
.attachlogo {
  height: 26px;
  width: 26px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 3px;
}
.attachlogos{
  height: 36px;
  width: 36px;
  cursor: pointer;
}
.infoattach {
  height: 24px;
  width: 24px;
  margin-left: 10px;
  padding-bottom: 3px;
}
.viewMore {
  color: #e60000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  cursor: pointer;
  border-bottom: 1px solid #e60000;
  font-weight: normal;
  margin-left: 10px;
}
.attachment {
  margin-top: 5px;
  margin-right: 10px;
  border: solid 1px #979797;
  display: flex;
  flex-direction: row;
  /* width: 256px; */
  /* height: 48px; */
  border-radius: 6px;
  border: solid 1px #979797;
  padding: 5px 5px 5px 1px
}
.filename {
  font-family: "VodafoneRg-Regular";
  /* font-weight: 700;
  padding-top: 25px; */
  margin-top: 8px;
  font-size: 16px;
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 3px;
}

.issue {
  color: #000000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
}
.summarydata {
  color: #666666;
  font-size: 14px;
  font-family: "VodafoneRg-Regular";
}
.summary {
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
}
.data {
  display: block;
  color: #000000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  /* padding-left: 3px; */
  word-wrap: break-word;
}
.header {
  display: flex;
  justify-content: space-between;
}
.status {
  color: #333;
  font-family: "VodafoneRg-Bold";
  /* background-color: #eb9700; */
  font-size: 14px;
  border-radius: 6px;
  padding: 5px 16px;
  height: 24px;
  margin-left: 10px;
}
 /* status background color */
 .new{
  background-color: #FFF1BB;
  border: 2px solid #F4D55B
}
.assigned{
  background-color: #00B0CA;
}
.pending{
  background-color: #BD0000;
}
.in_progress{
  background-color: #FFE1AB;
  border: 2px solid #F4B035
}
.resolved{
  background-color: #C7FFE6;
  border: 2px solid #80D8B0
}
.closed{
  background-color: #E8E8E8;
  border: 2px solid #C2C2C2
}
.need_more_information{
  background-color: #D5F9FF;
  border: 2px solid #89DBE9
}
.customer_feedback_awaited{
  background-color: #EEECFF;
  border: 2px solid #9188E0
}
.backbutton {
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-bottom: 4px;
  margin-right: 8px;
}
.back {
  margin: 16px 0px;
  display: flex;
  justify-content: space-between;
}
.escalationmatrix{  
  padding-top: 2px;
}
.escalation{
  color: #e60000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  cursor: pointer;
  border-bottom: 1px solid #e60000;
  font-weight: normal;
 
}
.listhead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.addcomment {
  height: 46px;
  width: 100%;
  border: 1px solid #979797;
  border-radius: 6px;
  font-size: 20px;
  padding: 0px 10px;
  color: #ffffff;
  background-color: #666666;
  font-family: "VodafoneRg-Regular";
}
.id {
  color: #333333;
  font-weight: 700;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
}
.commentContainer {
  border: 1px solid #979797;
  border-radius: 6px;
  padding: 10px;
  background-color: #ffffff;
  margin: 10px 0px;
}
.initalshead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.initatname {
  font-weight: 700;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
}
.initaltext {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  color: #333333;
  font-family: "VodafoneRg-Regular";
}
.inital {
  height: 36px;
  width: 36px;
  font-size: 16px;
  color: #ffffff;
  border-radius: 25px;
  line-height: 36px;
  margin-right: 8px;
  text-align: center;
  font-family: "VodafoneRg-Regular";
}
.servicedesk{
   background-color: #5E2750;
}
.customer{
  background-color: #dc2b2b;
}
.comment {
  font-size: 18px;
  font-family: "VodafoneRg-Regular";
  color: #333333;
}
.warning{
  color: #000000;
  font-size: 14px;
  font-family: "VodafoneRg-Regular";
  padding: 8px;
  /* margin-left: 10px; */
  font-weight: 400px;
  margin-top: 8px;
  line-height: 16.07px;
  border-radius: 6px;
  background-color: #FFEECC;
}
.circleinfo{
  height: 22px;
  width: 22px;
  margin-right: 5px;
  cursor: pointer;
  margin-bottom: 3px;
}
/* Escalation */

.warningpopup {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  
  overflow: auto;
  background-color: rgba(132, 131, 131, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.warningcontent {
  background-color: #FFFFFF;
  /* padding: 24px; */
  border: 1px solid #888;
  border-radius: 5px;
  width: 599px;
}
.head {
  font-family: "VodafoneRg-Regular";
  font-size: 24px;
  padding: 24px 24px 0px 24px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #333333;
  margin: 0px 0px 10px 0px;
  display: flex;
}
.headcontent {
  font-family: "VodafoneRg-Regular";
  margin: 0px 24px 0px 24px;
  padding: 7px 8px 7px 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000; 
  border-radius: 6px;
  background-color: #ffebcd;
}
.excellectioncontent{
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.buttons{
  display: flex;
  justify-content: center;
  position: relative;
}
.logout{
  position: absolute;
  bottom: 20px;
  font-family: "VodafoneRg-Regular";
  height: 40px;
  width: 166px;
  padding: 0px 16px 0px 16px;
  border-radius: 6px ;
  color: #ffffff;
  border: 1px solid #999999;
  background-color: #dd0000;
  justify-content: end;
  cursor: pointer;
}
.downloadtxt{
  margin-left: 20px;
  font-size: 14px;
  font-family: "VodafoneRg-Regular";
  color: #e60000;
}
.downloadicon{
  height: 14px;
  width: 14px;
  margin-top: 2px;
  margin-left: 4px;
}
.line{
  margin-top: 10px;
  border-bottom: 1px solid #666666;
}
.escalationcontent{
  padding: 24px;
  overflow-x: hidden; 
  overflow-y: auto;
  max-height: 545px;
margin-bottom: 4px;
}

.escalationlist{
  border: 0.5px solid #666666 ;
  border-radius: 6px;
  display: flex;
  margin-bottom: 10px;
}
.escalationlists{
  border: 0.5px solid #666666 ;
  border-radius: 6px;
  display: flex;
  background-color: #f1f1f1;
  margin-bottom: 10px;
}
.listdivs{
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  padding-right: 90px;
}
.listdiv{
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.listcontent {
  position: relative;
  padding-left: 10px; 
  margin-left: 20px;
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
}
.listcontents {
  /* padding-left: 10px;  */
  margin-left: 20px;
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
}
.fontweight{
  font-weight: 700;
  font-size: 14px;
  font-family: "VodafoneRg-Regular";
}
.listcontent::before{
content: ''; 
position: absolute; 
left: 0;
top: 50%; 
transform: translateY(-50%); 
width: 5px; 
height: 5px; 
background-color: black; 
border-radius: 50%; 
}
.leveldiv{
  border-radius: 5px 0px 70px 0px; 
  background-color: #007C92;
  margin-bottom: 15px;
  font-size: 14px;
  color: #ffffff;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  width: 70px;
  padding: 5px 20px 5px 10px;
}
.listcontents ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 14px;
}

.listcontents li {
  list-style-position: outside; 
  padding-left: 0px; 
}

.leveldivs{
  border-radius: 5px 0px 150px 0px; 
  background-color: #989898;
  margin-bottom: 15px;
  font-size: 12px;
  color: #ffffff;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  width: 130px;
  padding: 5px 20px 5px 10px;
}
.servicediv{
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  font-weight: 700;
}
.principlesmain{
  background-color: #f4f4f4;
  margin-bottom: 55px;
  border-radius: 6px;
  margin-top: 30px;
  padding-bottom: 15px;
}
.principleshead{
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  padding: 24px 0px 15px 0px;
  font-weight: 700;
  line-height: 18.34px;
  text-align: left;
  color: #333333;
  margin-left: 15px;
}
.principlesinfo{
  font-family: "VodafoneRg-Regular";
  padding: 2px 10px 2px 15px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
}

.principlesinfo ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 14px;
}

.principlesinfo li {
  list-style-position: outside; 
  padding-left: 0px; 
}
.headflex{
  display: flex;
  justify-content: space-between;
}
.closediv{
  margin-right: 20px;
  margin-top: 10px;
}
.closeimg{
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-top: 15px;
}

.sideNavStyle{
  width: 60% !important;
}
.viewmore{
  display: flex;
  justify-content: flex-end;
}

.detailContainer{
  border: 1px solid #979797;
  border-radius: 6px;
  padding: 10px;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.detailContent {
  background-color: #ffffff;
  border-radius: 6px;
  color: rgb(0, 0, 0);
}

.child {
  width: 25%;
  color: #666666;
  font-size: 16px;
  padding-right: 20px;
  font-family: "VodafoneRg-Regular";
}

.sepration {
  border-right: 1px solid #e6e6e6;
}
.borderPadding{
  padding-left: 20px;
}

.parent{
  display: flex;
  flex-wrap: wrap;
}

.comentsLoader{
  min-height: 150px;
  display: flex;
  justify-content: center;
}

.activityContainer{
padding: 16px 20px 16px 20px;
gap: 0px;
border-radius: 6px;
border: 1px solid #979797;
display: flex;
flex-wrap: wrap;
margin-bottom: 20px;
background-color: #FFFFFF;
}

.addinfoContainer{
  flex: 0 0 70%;
  gap: 12px;
}

.provideinfoContainer{
  flex: 0 0 30%;
  margin: auto 0;
}

.contentInfo{
font-family: "VodafoneRg-Regular";
font-size: 18px;
font-weight: 700;
line-height: 24px;
text-align: left;
margin-bottom: 10px;
}

.providebtn{
  display: block;
  float: right;
}

.provideinfobtn{
  width: 194px;
  background-color: #666666;
  color: #FFFFFF;
}
.feedbacktext{
  flex: 0 0 50%;
  margin: auto 0;
}

.feedbacaction{
  flex: 0 0 50%;
  margin: auto 0;
}

.feedbackInfo{
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin-bottom: 10px;
  font-size: 18px;
}

.feedbackbtn{
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

@media (max-width: 768px) {
  .feedbacktext, .feedbacaction, .addinfoContainer, .provideinfoContainer {
      flex: 0 0 100%;
  }
  .commonbtncss{
    margin-top: 12px;
  }
}
.emptydiv{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  background-color: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #979797;
}

.downloadpdf{
  cursor: pointer;
}

.title {
  color: #333333;
  font-size: 18px;
  font-family: "VodafoneRg-Regular";
}

.attachments{
  color: #666666;
  font-size: 16px;
  padding-right: 20px;
  font-family: "VodafoneRg-Regular";
}
.date {
  color: #000000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  padding-left: 3px;
  word-wrap: break-word;
}

.closedItsmid{
  color: #333333;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  line-height: 18.37px;
  border-radius: 6px;
  background-color: #FFEBCD;
  margin-bottom: 10px;
  gap: 5px;
  padding: 7px 8px;
}
.ticketIdServiceDesk{
  font-size: 16px;
  font-weight: 700;
  line-height: 18.34px;
  font-family: "VodafoneRg-Regular";
}

.portalcomment{
  display: block;
}
.issuetitle{
  color: #666666;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
}