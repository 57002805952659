.mainContainer {
  border: 1px dashed #BEBEBE;
  width: 100%;
  height: 178px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 36px;
  height: 36px;
}
.text {
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  color: #000000;
 
}
.dragdrop {
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  font-weight: 700;
  color: #000000;
 margin-top: 8px;
}
.button {
  background-color: #ffffff;
  border: 1px solid #999999;
  font-family: "VodafoneRg-Regular";
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  cursor: pointer;
  width: 148px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  /* padding: 12px 20px 12px 20px; */
  margin-top: 16px;
  border-radius: 6px;
}
