.container{
    border-radius: 6px;
    border: 1px solid #979797;
    background-color: #FFFFFF;
    overflow-x: hidden;    
    padding: 10px;
    gap: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
    /* height: 180px; */
}
.listContent{
    display: flex;
    flex-direction: row;
}
.actionContent{
    display: flex;
    justify-content:flex-end;
}
.list{
    flex: 1;
    max-width: 25%;
}
.label{
    color: #666666;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    padding-bottom: 2px;
    margin-top: 10px;
}
.labele{
    padding-top: 2px;
    color: #0E0E0E;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
     margin-top: 10px;
}
.value{
    color: #000000;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}
.backbutton{
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-bottom: 4px;
    margin-right: 8px;
  }
  .back{
      margin: 16px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
  }
  .id{
    color: #333333;
    font-weight: 700;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
  }
.alert{
    border-radius: 6px;
    font-size: 14px;
    margin-bottom: 20px;
    font-family: "VodafoneRg-Regular";
    color: #333333;
    background-color: #FFEECC;
    padding: 3px;
    padding-left: 5px;
}
.tadiglabel{
    color: #666666;
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
}
.tadigvalue{
    color: #000000;
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
    font-weight: 700;
}
.feedbacaction{
    flex: 0 0 50%;
    margin: auto 0;
  }
/*   
  .feedbackInfo{
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
  } */
  .containerapproval{
    
    overflow-x: hidden;    
    padding: 10px;
    gap: 16px;
    margin-top: 20px;
    height: 80px;
}
  .approvalq{
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
    display: flex;
    margin-top: 10px;
  }
  .feedbackbtn{
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  .comment{
    height: 100px;
    border: 1px solid #979797;
    border-radius: 6px;
    background-color: #FFFFFF;
    font-size: 20px;
    text-align: center;
    padding-top: 30px;
  }
  .listhead{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .initaltext{
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .add{
    color: #333333;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
  }
  .addform{
    color: #E60000;
    font-family: "VodafoneRg-Regular";
    cursor: pointer;
    border-bottom: 1px solid #E60000;
  }
.requestDetail{
  background-color: #EBEBEB;
  padding: 3px 6px;
  margin: 5px 0px;
  border-radius: 4px;
  color: #333;
  font-family: "VodafoneRg-Bold";
  font-size: 14px;
}
.comtext{
  font-family: "VodafoneRg-Regular";
  font-size: 24px;
  color: #333333;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.status{
  border-radius: 30px;
  color: #fff;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  margin-left: 10px;
  margin-top: 8px;
  padding: 3px 16px;
  height: 24px !important;
}
/* status background color */
.pending{
  background-color: #EB9700;
}
.approved{
  background-color: #009900;
}
.commentsection{
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 20px 0px;

}
.grantAccess{
  display: flex;
  justify-content: end;
}

.commonbtncss{
  border-radius: 6px;
  padding: 8px 16px;
  background-color: #E60000;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  font-family: "VodafoneRg-Regular";
  color: #FFFFFF;
  border: none;
}

.btnLoaderContainer{
  width: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnLoader{
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
}