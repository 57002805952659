/* styles.css */



.spinnercontainer {
    /* width: 80px;
    height: 80px; */
    margin: auto;
    /* border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    background-color: var(--bg-loader); */
}

.spinnersvgcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 12px 12px; */
   
}

.spinnersvg {
    width: 60px;
    height: 60px;
}

.spinnerpath1,
.spinnerpath2,
.spinnerpath3 {
    fill: none;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-dasharray: 120, 32;
    stroke: currentColor;
}

.spinnerpath1 {
    stroke-width: 2;
    stroke: rgba(238, 3, 3, 0.3);
    animation: dashdraw 3.5s linear 0.2s infinite;
}

.spinnerpath2 {
    stroke-width: 3;
    stroke: rgba(220, 38, 38, 0.6);
    animation: dashdraw 3.5s linear 0.1s infinite;
}

.spinnerpath3 {
    stroke-width: 4;
    stroke: rgba(220, 38, 38, 1);
    animation: dashdraw 3.5s linear infinite;
}
.spinnerred{
    stroke: #e53e3e; /* Tailwind's red-600 */
    fill: #e53e3e

}
@keyframes dashdraw {
    100% {
        stroke-dashoffset: 610;
    }
}
