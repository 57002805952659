.warningpopup {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .warningcontent {
    background-color: #fefefe;
    padding: 24px;
    border: 1px solid #888;
    border-radius: 5px;
    width: 491px;
  }
  .head {
    font-family: "VodafoneRg-Regular";
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #333333;
    margin: 20px 0px 20px 0px;
  }
  .headcontent {
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
    margin-bottom: 30px;
  }
  .buttons{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .logout{
    font-family: "VodafoneRg-Regular";
    height: 40px;
    padding: 0px 16px 0px 16px;
    gap: 10px;
    border-radius: 6px ;
    color: #000000;
    border: 1px solid #999999;
    background-color: #ffffff;
    cursor: pointer;
  }
  .continue{
    cursor: pointer;
    font-family: "VodafoneRg-Regular";
    height: 40px;
    padding: 0px 16px 0px 16px;
    gap: 10px;
    border-radius: 6px ;
    background-color: #E60000;
    color: #ffffff; 
    border: 1px solid #999999;
  }

  .okBtn{
    font-family: "VodafoneRg-Regular";
    height: 40px;
    width: 140px;
    padding: 0px 16px 0px 16px;
    border-radius: 6px ;
    color: #000000;
    border: 1px solid #999999;
    background-color: #ffffff;
    justify-content: end;
    cursor: pointer;
  }