
.label-head{
  padding-left: 20px;
  font-size: 14px;
  }

.label{
    padding-left: 15px;
   
    font-size: 12px;
  }
  /* .container hr{
    margin-top: px;
  } */
  
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto ;
    padding: 10px;
  }
  .grid-item {
    padding: 8px;
    font-size: 12px;
  }
  .bold{
    font-weight: bold;
  }
  
  .sidenav-container {
      display: flex;
      align-items: center;
      border-radius: 6px 0px 0px 6px
    }
    
    .open-btn {
      font-size: 14px;
      cursor: pointer;
      margin: 20px; 
      color: #f1f1f1;
      background-color: red;
      width: 50px;
      height: 20px;
    }
    
    .sidenav {
      height: 100vh;
      width: 0;
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      background-color: #ffffff;
      overflow-x: hidden;
      transition: 0.5s;
      padding-top: 40px;
    }
    
    .sidenav.open { /* Add class dynamically using JavaScript */
      width: 650px;
    }
    
    .sidenav a {
      text-decoration: none;
      color: #d12222;
      display: block;
      transition: 0.3s;
    }
  
    
    .sidenav a:hover {
      color: #f1f1f1;
    }
    .btn-save{
       background-color: rgb(255, 0, 0);
       color: #f1f1f1;
       margin:0px 10px 0px  15px;
       padding:5px 20px 5px 20px;
       border: 2px solid  #E60000;
       border-radius: 6px;
    }
    .btn-commentHistory{
   

      color: #020202;
      margin:0px 10px 0px  15px;
      padding:5px 20px 5px 20px;
      border: 1px solid #666666;
      border-radius: 6px;
    }
    .commentBox img{
      width: 30px;
       height: 30px;
       cursor: pointer;
  
  }
    .bottom-flex{
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }
    .text-comment textarea{
      width: 615px;
      height: 90px;
     margin:5px 0px  10px 15px;
  
     padding: 5px;
    }
    .error-txt {
      font-size: 10px;
      margin-left: 15px;
      color: red;
    }
  
    .btn-select{
      width: 615px;
      margin:5px 0px  0px 15px;
      height: 150px;
      border-style: dotted;
    }
    
    .sidenav .closebtn {
      position: absolute;
      top: 0;
      right: 25px;
      font-size: 36px;
      margin-left: 50px;
    }
    
    @media screen and (max-height: 450px) {
      .sidenav {
        padding-top: 15px;
      }
      .sidenav a {
        font-size: 18px;
      }
    }
    