.bannerContainer {
    display: flex;
    /*flex: 1;*/
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.textbanner {
    position: absolute;
    /* font-size: 34px; */
    font-weight: 400;
    margin-bottom: 6px;
  }
  .text{
   text-align: center;
    font-size: 34px;
    color: #FFFFFF;
    font-family: "VodafoneLt-Regular";
  }
.image{
    width: 100%;
    height: 160px;/* height: 192px; */
}
.bannerimage{
    width: 100%;
    height: 120px;
}
.buttondiv{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-right: 10px;
}
.buttonContainer{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.description{
    font-size: 18px;
    font-family: "VodafoneLt-Regular";
    color: #FFFFFF;
    text-align: center;
}