
.collapsibleContainer {
    border: 1px solid #979797;
    border-radius: 6px;
    padding: 10px;
    background-color: #FFFFFF;
    margin-bottom: 20px;
  }
  
  .collapsible {
    background-color: #FFFFFF;
    border-radius: 6px;
    color: rgb(0, 0, 0);
  }
  
  .content {
    border-radius: 6px;
    display: none;
    background-color: #FFFFFF;
    color: rgb(0, 0, 0);
    transition: max-height 0.3s ease-in-out;
  }
  
  .content.active {
    display: block;
  }
  
  .open {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFFFFF;
    color: #666666;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
  }
  
  .close {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFFFFF;
    color: #666666;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
  }
  
  .arrowdown {
    position: absolute;
    top: 1px;
    left: 80px;
  }
  
  .arrowup {
    position: absolute;
    left: 70px;
    top: 1px;
  }
  
  .titleContainer {
    display: flex;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  .ivcContainer{
    margin-bottom: 10px;
    margin-top: 5px;
  }
  
  /* .detailmain{
    border-top: 1px solid #E6E6E6;
    padding-top: 10px;
    display: flex;
    flex: 1;
    margin-top: 8px;
  } */
  .ivcDetailsDiv{
    color: #666666;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    margin-bottom: 10px;
  }
  .DetailsDiv {
    /* color: #666666;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    flex: 1;
    border-right: 1px solid #E6E6E6;
    text-align: center; */
    width: 20%;
    color: #666666;
    font-size: 16px;
    padding-right: 20px;
    font-family: "VodafoneRg-Regular";
  }

  .sepration {
    border-right: 1px solid #e6e6e6;
  }
  .borderPadding{
    padding-left: 20px;
  }
  /* .DetailsDiv:first-child {
    text-align: left
  }
  .DetailsDiv:last-child {
    border-right: none;
  } */
  .ViewmoreDiv{
    color: #666666;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    margin-bottom: 10px;
  }
  .Divattachment{
    color: #666666;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
  .ViewmoreDivattachment{
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* margin-top: 10px; */
    padding:10px 0px 10px 0px
  }
  .attachmentDiv {
    width: 250px;  
    display: flex;
    flex-direction: row;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #666666;
    align-items: center;
    gap:10px;
    min-height: 40px;   
    padding: 4px;
}
.attachmentName {
    flex: 2;
    flex-direction: row;
    color: #333333;
    font-family: "VodafoneRg-Regular";
    font-size: 17px;
    font-weight: 400 !important;
    font-style: normal;

    width: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.download {
  height: 26px;
  width: 26px;
  margin-right: 10px;
  cursor: pointer;
}
  .attachlogo{
    height: 26px;
    width: 26px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 5px;
  }
  .attachlogos{
    height: 36px;
    width: 36px;
    cursor: pointer;
  }
.attachmentDetailsDiv{
    color: #666666;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";

    display: flex;
    flex-direction: row;
    gap: 10px;
    /* margin-top: 10px; */
    padding:10px 0px 10px 0px
}
  .infoattach{
    height: 24px;
    width: 24px;
    margin-left: 10px;
    padding-bottom: 3px;
  }
  .feedbackInfo{
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
  }
  .circleinfo{
    height: 14px;
    width: 14px;
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 3px;
  }
  .viewMore{
    color: #E60000;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    cursor: pointer;
    border-bottom: 1px solid #E60000;
    font-weight:normal;
  }
  .attachment{
   height: 36px;
   width: 36px;
   display: flex;
  }
  .attachments{
    margin-top: 5px;
  }
  .filename {
    font-family: "VodafoneRg-Regular";
    /* font-weight: 700;
    padding-top: 25px; */
    font-weight: 400;
    margin-top: 8px;
    font-size: 16px;
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .issue{
    color: #666666;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
  }
  .summarydata{
    display: block;
    color: #333333;
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
    /* font-weight: 700; */
    width: 100%;
  }
  .ipxborder{
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #E6E6E6;
  }
  .summary{
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #E6E6E6;
    width: 100%;
  }
  .data {
    display: block;
    color: #000000;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    /* padding-left: 3px; */
    word-wrap: break-word;
  }
  .nodata{
    display: block;
  }
  .header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
.status{
    color: #333;
    font-family: "VodafoneRg-Bold";
    /* background-color: #EB9700; */
    font-size: 14px;
    border-radius: 6px;
    padding: 4px 16px;
    height: 24px;
    margin-left: 10px;
}
.backbutton{
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-bottom: 4px;
  margin-right: 8px;
}
.back{
    margin: 16px 0px;
}
.listhead{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.addcomment{
  height: 46px;
  width: 100%;
  border: 1px solid #979797;
  border-radius: 6px;
  font-size: 20px;
  padding: 0px 10px;
  color: #FFFFFF;
  background-color: #666666;
  font-family: "VodafoneRg-Regular";
}
.id{
  color: #333333;
  font-weight: 700;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";

}
.commentContainer{
  border: 1px solid #979797;
  border-radius: 6px;
  padding: 10px;
  background-color: #FFFFFF;
  margin-bottom: 10px;
}
.initalshead{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

}
.initatname{
  font-weight: 700;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
}
.initaltext{
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  color: #333333;
  font-family: "VodafoneRg-Regular";
}
.inital{
  height: 36px;
  width: 36px;
  font-size: 16px;
  color: #ffffff;
  border-radius: 25px;
  line-height: 36px;
  text-align: center;
  margin-right: 8px;
  font-family: "VodafoneRg-Regular";
}
.servicedesk{
  background-color: #5E2750;
}
.customer{
 background-color: #dc2b2b;
}
.comment{
  font-size: 18px;
  font-family: "VodafoneRg-Regular";
  color: #333333;
}
 /* status background color */
 .new{
  background-color: #FFF1BB;
  border: 2px solid #F4D55B
}
.assigned{
  background-color: #00B0CA;
}
.pending{
  background-color: #BD0000;
}
.in_progress{
  background-color: #FFE1AB;
  border: 2px solid #F4B035
}
.resolved{
  background-color: #C7FFE6;
  border: 2px solid #80D8B0
}
.closed{
  background-color: #E8E8E8;
  border: 2px solid #C2C2C2
}
.need_more_information{
  background-color: #D5F9FF;
  border: 2px solid #89DBE9
}
.customer_feedback_awaited{
  background-color: #EEECFF;
  border: 2px solid #9188E0
}
.Emptydiv{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  background-color: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #979797;
}

.parent{
  display: flex;
  flex-wrap: wrap;
}

.ivcdetails{
  display: flex;
  flex: 1;
}
.border{
  margin: 0px 40px 0px 0px;
}

.commentattachment{
  margin-top: 5px;
  margin-right: 10px;
  border: solid 1px #979797;
  display: flex;
  flex-direction: row;
  /* width: 256px; */
  /* height: 48px; */
  border-radius: 6px;
  border: solid 1px #979797;
  padding: 5px 5px 5px 1px
}

.attachments{
  color: #666666;
  font-size: 16px;
  padding-right: 20px;
  font-family: "VodafoneRg-Regular";
}
.comentsLoader{
  min-height: 150px;
  display: flex;
  justify-content: center;
}

.date {
  color: #000000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  padding-left: 3px;
  word-wrap: break-word;
}

.activityContainer{
  padding: 16px 20px 16px 20px;
  gap: 0px;
  border-radius: 6px;
  border: 1px solid #979797;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  background-color: #FFFFFF;
  }

  .addinfoContainer{
    flex: 0 0 70%;
    gap: 12px;
  }

  .provideinfoContainer{
    flex: 0 0 30%;
    margin: auto 0;
  }

  .providebtn{
    display: block;
    float: right;
  }

  .feedbacktext{
    flex: 0 0 50%;
    /* gap: 12px; */
    margin: auto 0;
  }

  .feedbacaction{
    flex: 0 0 50%;
    margin: auto 0;
  }

  .feedbackbtn{
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

  .contentInfo{
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
    }

    .warning{
      color: #000000;
      font-size: 14px;
      font-family: "VodafoneRg-Regular";
      padding: 8px;
      /* margin-left: 10px; */
      font-weight: 400px;
      margin-top: 8px;
      line-height: 16.07px;
      border-radius: 6px;
      background-color: #FFEECC;
    }

  @media (max-width: 768px) {
    .feedbacktext, .feedbacaction, .addinfoContainer, .provideinfoContainer {
        flex: 0 0 100%;
    }
    .commonbtncss{
      margin-top: 12px;
    }
  }

  .portalcomment{
    display: block;
  }
  
.infotext{
  font-weight: 400;
  font-family: "VodafoneRg-Regular";
  /* margin-left: 5px; */
  font-size: 16px;
  line-height: 28px;
  color: #333333;
  /* background-color: #979797; */
  /* cursor: all-scroll; */
 margin-bottom: 10px;
  border: 1px solid #999999;
  border-radius: 6px;
  padding: 2px 10px;
  max-height: 90px;
  overflow: auto;
  width: 100%;
}
.infotexts{
  font-weight: 400;
  font-family: "VodafoneRg-Regular";
  /* margin-left: 5px; */
  font-size: 16px;
  line-height: 28px;
  color: #333333;
  width: 100%;
}
.info{
  font-weight: 400;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
}
.issuetitle{
  color: #666666;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
}