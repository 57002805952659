/*CSS*/
.addButton {
    width: 100%;
    height: 48px;
    font-size: 20px;
    border-radius: 6px;
    /* margin-top: 24px; */
    padding: 0px 15px;
    color: #ffffff;
    border: 1px solid #999999;
    font-family: "VodafoneRg-Regular";
    background-color: #666666;
  }
  .fieldContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 24px;
  }
 .obj{
 display: contents;
    }
  .select{
    margin-top: 25px;
  }
 
  .input {
    width: "auto";
    margin-bottom: 0px;
  }
  .chackboxtxt{
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    color: #666666;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .inputStyle{
    min-width: 500px;
  }
  .inputError,
  .selectError {
    border-color: #cd3500 !important;
  }
  
  .label {
    font-family: "VodafoneRg-Regular";
    margin-bottom: 10px;
    color: #100045;
  }
  

  
  .buttonContainer {
    display: flex;
    padding: 18.5px 16px;
  }
  
  .backButton {
    background-color: #666666;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 88px - 21px);
  }
  
  .form fieldset {
    padding: 6px 14px 0 14px;
  }
    
  .separator {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-top: 10px;
    border-color: #666;
    transform: scaleX(1.5);
    opacity: 100;
  }
  .accountnotfount{
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    color: #666666;
    margin-top: 5px;
    margin-bottom: 15px;
    text-align: center;
  }
  .sideNavStyle{
    width: 60% !important;
    overflow: hidden;
    }

    .amnote{
      display: block;
      font-family: "VodafoneRg-Regular";
      /* margin: 0px 24px 0px 24px; */
      padding: 7px 8px 7px 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #000000; 
      border-radius: 6px;
      background-color: #ffebcd;
    }
    .navigatevf{
      color: #e60000;
      /* margin-left: 10px; */
      text-decoration: underline;
      cursor: pointer;
    }