.submitButton {  
  width: 320px;
  text-align: center;
  margin: 0px; 
  font-family: "VodafoneRg-Regular";
  margin-bottom: 20px;
}

.headerText {
  color:  #333333;
  font-family: "VodafoneRg-Regular";
  font-size: 24px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.or{
  font-family: "VodafoneRg-Regular"; 
  padding: 15px;
}
.resetLink{
  font-family: "VodafoneRg-Regular"; 
  padding-top: 20px;
}