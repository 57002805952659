.App {
   /*height: 700px;*/
    min-height:calc(100vh - 56px - 56px - 10px); /*56px header and 56px footer 10 is for mapContainer marging*/
    width: 100%;
    background-color: #fff;
}
  
.mapContainer{
    min-height:calc(100vh - 56px - 56px - 10px); /*56px header and 56px footer 10 is for mapContainer marging*/
    /*width: 1024px;*/
    background: "white";
    margin: auto;
    margin: 5px 5px;
}
.customerInfoSection{
    height: 250px;
    overflow: scroll;
}
/*-----Information Popup-----------*/
.popoverContainerRight {
    display: flex;
    background: #FFFFFF;
    width: 350px;
    padding: 16px 16px;
    flex-direction: column;
    position: absolute;
    top: 130px;
    right: 70px;
    border-radius: 10px;
    z-index: 1;
}
.popoverContainerLeft {
    display: flex;
    background: #FFFFFF;
    width: 350px;
    padding: 16px 16px;
    flex-direction: column;
    position: absolute;
    top: 130px;
    left: 70px;
    border-radius: 10px;
    z-index: 1;
}
.popoverHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
}
.headerIcon{
    height: 24px;
    width: 24px;
}
.headerTitle{
    font-size: 16px;
    font-family: "vodafoneRg-bold";
    color: #333333;
}
.headerSubTitle{
    font-family: "vodafoneRg-Regular";
    font-size: 12px;
    color: #333333;
}
.fieldContainer{
    display: flex;
    margin-bottom: 16px;
}
.fieldLabel{
    flex: 1;
    width:100px;
    font-family: "vodafoneRg-Regular";
    font-size: 14px;
    color: #666666;
}
.fieldvalue{
    flex: 2;
    text-align: right;
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    color: #333333;
}
.hrLine{
    border-bottom: 1px solid;
    border-color: rgba(75, 77, 79, 0.2);
    margin-bottom: 16px;
}
.customerSection{
    font-family: "vodafoneRg-Regular";
    font-size: 12px;
    color: #666666;
}
/*---------collapsible-----*/
.collapsibleListScroll{
    max-height: 200px;
    overflow: scroll;
}
.collapsibleList{
    margin-top: 16px;
}
.collapsibleHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.collapsibleHeaderTitle{
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    color: #333333;  
}
.hidecollapsibleContent{
    display: none;
}
.showcollapsibleContent{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid;
    border-color: rgba(75, 77, 79, 0.2);
    padding: 16px 0px;
}
.itemContainer{
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 6px 0px;
}
.showcollapsibleContentItem1{  
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    color: #666666;  
}
.showcollapsibleContentItem2{
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    color: #ffffff;
    padding: 0px 4px;
    border-radius: 30px;
    padding: 0px 16px;
    text-align: center;
}
.liveService{
    background-color: rgb(35, 132, 36);
}
.shutdownService{
    background-color: rgb(0, 124, 146);
}
.noDataMsg{
    font-family: "VodafoneRg-Regular";
    font-size: 12px;
    color: #333333;  
    margin: 8px 0px;
}
.closeIconContainer{
    display: flex;
    justify-content: flex-end;
}
.close{
    cursor: pointer;
    height: 16px;
    width: 16px;
}
/*--------------------------*/


/*CSS For pop over tooltip*/
.hoverTooltipContainer{
    display: flex;
    padding: 16px 16px;
    flex-direction: column;
}
.popoverTitle{
    font-family: "VodafoneRg-Bold";
    font-size: 16px;
}
/*-----------------------------*/
