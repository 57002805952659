.buttonContainer {
  display: flex;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  color: #666666; 
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  font-style: normal; 
}
.backButton {
  background-color: #666666;
}
.labelcomment{
  margin-bottom: 6px;
}
.separator{
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-top: 16px;
    margin-bottom: 16px !important;
    border-color: #666;
    transform: scaleX(1.5);
    opacity: 100;
}
.commentValidation {
  color:#666666;
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  text-align: right;
}
.commentpart{
  height: calc(100vh - 112px - 106px);
  overflow: auto;
  padding: 0 5px;
}