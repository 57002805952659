.notification{
    display: flex;
    border: 1px solid #CCCCCC;
    align-self: center;
    justify-content: center;
    margin-bottom: 3px;
    margin-top: 3px;
    border-radius: 6px;
    background-color: #c9d07ba5;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 700;
}