/*Add Legends css here*/
.legendContainer{
    width: 200px;
    border-radius: 8px;
    border: 1px solid;
    border-color: rgba(204, 204, 204, 0.5);
    position: absolute;
    bottom: 85px;
    left: 14px;
    background-color: #333333;
    padding: 8px 8px;
}
.legendItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
}
.legendlabel{
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    color: #ffffff;
    font-family: "VodafoneRg-Bold";
}
.legendIcon{
    width: 16px;
    height: 16px;
}
/*-----------------------------------*/
.title{
    background-color: #ffffff;
    padding: 10px;
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
    color: #333333;    ;
    font-weight: 700;
}
/*--Map Filters--*/
.mapFilterChipsContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-end; 
    /*---CSS to move filter chips on map---*/
    width: max-content;
    margin-left: auto;
    position: absolute;
    top: 71px;
    right: 14px;
    z-index: 1;
    /*----------------------------------*/
}
.radioInput {
    display: none;
  }
  
  .mapCheckboxChips {
    display: flex;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
 
  }
  .mapCheckboxChips input[type='checkbox'] + label {
    color: #333333;
    font-family: "VodafoneRg-Regular";
    padding: 5px 16px 5px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    width: 180px;
    text-align: center;
  }
  .mapCheckboxChips input[type='checkbox']:checked + label {
    color: #ffffff;
    font-family: "VodafoneRg-Bold";
    padding: 5px 16px 5px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    width: 180px;
    text-align: center;
  }
  .radioLabel {
  }
/*---------------*/
/*-----InfoGraphicDetails-------------*/
.InfoGraphicDetailsContainer{
    display: flex;
    flex: 1;
    width: max-content;
    position: absolute;
    bottom: 85px;
    right: 14px;
    gap: 8px;
}
.InfoGraphicDetails_Item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    width: 166px;
    background-color: #FFFFFF;
    border-radius: 8px;
}
.InfoGraphicDetails_Lable{
    font-family: "VodafoneRg-Regular";
    font-size: 12px;
    color: #000000;
}
.InfoGraphicDetails_Count{
    font-family: "VodafoneRg-Bold";
    font-size: 24px;
    color: #E60000;
    
}
/*----------------Tootltip----------------------*/

.tooltipBody{
    background: #fff;
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    color: #000000;
    max-width: 200px;
    padding: 8px 8px;
    border-radius: 8px;
}