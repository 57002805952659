.vfLogin {
  width: 395px !important;
  height: 48px !important;
  align-items: center !important;
  margin: 0px !important;
  border: 1px solid #FFFFFF !important;
  padding: 12px 16px 12px 16px !important;
  border-radius: 6px !important;
}

.vfLoginContainer {
  /* margin: 44px 88px 0 0; */
  /* min-height: 308px; */
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  color: #666666;
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  font-style: normal;
}

.passwordtxt .textbox::-ms-reveal 
{   
    display: none;
}

.textbox {
  
  width: 395px !important;
  height: 52px !important;
  padding: 12px !important;
  border-radius: 6px !important;
  /* margin-bottom: 20px; */
}
.inputError,
.selectError {
  border-color: #CD3500 !important
}
/* .textbox{
  color: #FFFFFF !important;
} */
 /* .errormessage{
  color: #FFFFFF !important;
 } */
.passwordtxt {
  margin-top: 20px;
  margin-bottom: 20px;
}

.submit {
  width: 395px !important;
  margin: 0px !important;
  height: 48px !important;
  margin-left: 0px !important;
  border: 1px solid #FFFFFF !important;
  padding: 12px 16px 12px 16px !important;
  border-radius: 6px !important;
}

.loader{
  width:395px !important;
  align-items: center;
}

.customerLoginContainer {
  padding: 0 80px;
}

.headerText {
  /* height: 62px;
  width: 558px;
  margin-bottom: 20px;
  padding-right: 150px; */
  font-family: "VodafoneRg-Regular";
  font-size: 56px;
  font-style: normal;
  text-align: center;
  color: #333333;
  font-weight: 700;
}
/* 
.headerLogo {
  text-align: center;
} */

.logo {
  height: 48px;
  width: 48px;
  /* margin-left: 16px; */
}

.passwordToggleIcon {
  position: relative;
  float: right;
  bottom: 25px;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}
.ForgotPasswordlink {
  color: #E60000 !important;
  font-family: "VodafoneRg-Regular";
  margin-top: 10px;
  font-size: 16px !important;
  text-align: center;
}
.linkButton {
  font-family: "VodafoneRg-Regular";
  padding-top: 20px;
  justify-content: center;
}
.forgotPasswordlink{
  color: #E60000 !important;
  font-family: "VodafoneRg-Regular";
  font-size: 16px !important;
  text-align: center;

}

.modalHeader {
  padding: 2px 16px;
  color: #333333;
  font-family: "VodafoneRg-Regular";
}
.modal {
  /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Body */
.modalBody {
  padding: 2px 16px;
  font-family: "VodafoneRg-Regular";
}

/* Modal Content */
.modalContent {
  position: relative;
  font-family: "VodafoneRg-Regular";
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 430px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.buttonContainer {
  display: flex;
}
.forgotSubmit {
  width: 150px !important;
}

.showforgotMessage {
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  color: #333333;
  background-color: #DAF3E3;
  border-radius: 4px;
  padding: 4px 12px;
}
.errorcolor{
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  color: #333333;
  background-color: #FFDADB;
  border-radius: 4px;
  padding: 4px 12px;
}
.modalHeader span {
  float: right;
  font-size: 25px;
  cursor: pointer;
}

.modalHeader h2 {
  margin-top: 15px;
}

.forgotText {
  font-family: "VodafoneRg-Regular";
  font-family: 18px;
  color: #000000;
}
.linkButton {
  justify-content: center;
}

.loaderContainer {
  width: 30px;
  height: 30px;
  margin: auto;
}
/* New login  */

.mainContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-image: url("../../assets/images/loginBackground.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: flex-end;
}
.loginContainer {
  /* border: 1px solid #ffffff; */
  padding: 24px;
  width: 443px;
  /*height: 100vh;*/
  align-items: center;
  background-color: #FFFFFF;
  /* margin: 101px 24px; */
}
.logoText {
  align-items: center;
  text-align: center;
}
.CustomerLogin {
  align-items: center;
}

.hrlines {
  position: relative;
  max-width: 500px;
  margin: 20px 0px;
  text-align: center;
  color: #333333;
  font-family: "VodafoneRg-Regular";
  font-size: 20px;
}

.hrlines:before {
  content: " ";
  height: 1px;
  width: 180px;
  /* background: #ffffff; */
  display: block;
  position: absolute;
  top: 50%;
  left: 20;
}

.hrlines:after {
  content: " ";
  height: 1px;
  width: 160px;
  /* background: #ffffff; */
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
}
.subContainer{
  height: 100vh;
  display: flex;
  background: #ffffff;
  align-items: center;
  justify-content: center;
}
.errormessage{
  color: #333333;
  font-family: "VodafoneRg-Regular";
  font-size: 18px;
  font-weight: 700;
}