.continue{
    border: 2px solid #FFFFFF;
    background-color: transparent;
    color: #FFFFFF;
    Width:223px;
    Height:40px;
    border-radius: 6px;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    /* Padding:12px 16px 12px 16px; */
    cursor: pointer; 
}
.stype{
  color: #666666;
  font-size: 20px;
 
  font-family: "VodafoneRg-Regular";
  align-items: center;
}
.buttonContainer {
    display: flex;
   
  }
.backButton{

}
.saveButton{

}
.separator {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-top: 10px;
  border-color: #666;
  transform: scaleX(1.5);
  opacity: 100;
}
.separatortop {
  margin-top: 25px;
  border-color: #666666;

  opacity: 100;
}
.submit{
    background-color: #E60000;
    color: #FFFFFF;
    border: none;
    Width:230px;
    Height:48px;
    border-radius: 6px;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    /* Padding:12px 16px 12px 16px; */
    cursor: pointer; 
}
.Buttons{
  margin-top: 400px;
}
  .radioLabel {
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 16px;
    color: #0d0d0d;
    padding-left: 5px;  
    margin-top: 2px;                   
  }

  .radioInput {
    width: 20px;
    height: 20px;
    accent-color: #00697c;
 margin-top: 3px;
  }
  
  .radioField {
    display: flex;
    
    flex-direction: row;
 
 
  }
  .radiostyle{
    margin-right: 240px;
  }
  .radiodiv{
    display: flex;
margin-top: 5px;
margin-right: 50px;
  }
  .radiodivs{
    display: flex;
margin-top: 5px;
margin-right: 160px;
  }
  .wrapperContainer{
    display: flex;
    align-items: center;
    flex: 1;
}
  .circle{
margin-left: 20px;
margin-top: 1px;
  }
  .circlered{
    /* margin-left: 5px; */
 margin-bottom: 3px;
      }
      .circleimg{
        margin-bottom: 3px;
      }
.note{
  color: #666666;
  font-size: 18px;
  font-family: "VodafoneRg-Regular";
  margin-bottom: 10px;
}
  /* ivc */
  .buttonContainer {
    display: flex;
  }
  
  .fieldWrapper {
    display: flex;
    flex-direction: column;
    color: #666666; 
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    font-style: normal; 
  }
  .backButton {
    background-color: #666666;
  }
  .labelcomment{
    margin-bottom: 6px;
  }
  .separator {
    margin-top: 16px;
    margin-bottom: 16px;
    border-color: 1px solid #666666;
    width: 100%;
    margin-left: -24px;
    opacity: 1 !important;
  }

  .commentValidation {
    color:#666666;
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    text-align: right;
  }
.textarea{
  margin-top: 25px;
}

/* IPX */
.dropdowngrid{
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}
.dropdownwidths{
  padding-right: 48px;
}
.dropdownwidth{
  flex: 1;
  
}
.maintextbox{
  display: flex;
}
.textbox{
  flex: 1;
  margin-bottom: 25px;
  margin-top: 5px;
  margin-right: 24px;
}
.continueboth{
  display: flex;
  /* border: 2px solid #FFFFFF; */
  border: none;
  background-color: #E60000;
  color: #FFFFFF;
  Width:223px;
  Height:48px;
  border-radius: 6px;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  Padding:12px 16px 12px 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer; 
}
.sideNavStyle{
  width: 60% !important;
}
.obj{
  width: 100%;
}
.FailuerTypeDropdownLabel{
  font-family: "VodafoneRg-Regular";
  font-size: 20px;
  color: #666666;
  font-weight: 400;
  flex: 1;
}
.FailuerTypeDropdown{
  flex: 2;
}
.form{
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 88px - 180px);
}
/* footer */
.footer {
  align-items: end;
  justify-content: end;
}
/* component level  */
.labelcompLevel{
  font-size: 18px;
}
.additionalStyle{
  font-size: 20px;
}

