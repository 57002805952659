/*CSS*/
.headContainer {
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
}

.vfuser {
    font-family: "VodafoneRg-Regular";
    font-size: 24px;
    font-weight: 400;
    color: #333333;
    align-items: center;
}
.VfUserListContainers{
  margin-top: 20px;
}
.addUserBtn {
    width: 221px;
    height: 48px;
    /* padding: 12px 20px 12px 20px; */
    font-size: 20px;
    border-radius: 6px;
    margin-top: 24px;
    color: #FFFFFF;
    border: 1px solid #999999;
    font-family: "VodafoneRg-Regular";
    background-color:  #666666;
    
}
.VfUserListContainer{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 25px;
  min-height: 95px;
  overflow-x: hidden;
  padding: 10px; /* padding: 20px; */
  background-color: #ffffff;
  border: 1px solid #979797;
  border-radius: 6px;
}
.VfUserList {
    flex: 1;
    color: #000000;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    padding-right: 20px;
  }
  .spanlabel {
    color: #666666;
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
  }
  .VfUserList p {
    color: #000000;
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
  }
  /* switch */
  .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7E7E7E;
    transition: .4s;
    /* cursor: not-allowed; */
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
 
    /* content: "\2713"; */
  }
  
  input:checked + .slider {
    background-color:#00697C;
    /* cursor: not-allowed; */
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
    /* content: "\2713"; */
  }
  
  /* Rounded sliders */
  .slider {
    border-radius: 34px;
  }
  
  .slider:before {
    border-radius: 50%;
  }
.spanSwitch{
  margin: 0px 5px;
  cursor: pointer;
}
/* SearchList */
.searchBar {
  margin-bottom: 0px;
margin-top: 0%;
}

.searchList {
  width: 340px;
}

.moduleAssignedHeader {
  font-family: "VodafoneRg-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.expanded {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 5 / span 5;
  gap: 10px;
}
.transparentBtn {
  background-color: transparent;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  border: none;
}
.radioField {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.radioInput {
  width: 20px;
  height: 20px;
  accent-color: #00697c;
}
.radioLabel {
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  color: #0d0d0d;
}

.sectionContainer{
  display: flex;
  margin-bottom: 16px;
}
.sectionTitle{
  font-family: "VodafoneRg-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-right: 16px;
}
.Icon{
  height: 19px;
  width: 19px;
  cursor: pointer;
}
.loaderContainer{
  width: 20px;
  height: 20px;
}
.active{
  margin-top: 20px;
}
.loaderStyle{
  height: 30px;
  width: 30px;
  padding: 0px !important;
  margin: 0px !important;
}
.CheckBoxswitch{
  cursor: pointer;
}