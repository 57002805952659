.container{
     border-radius: 6px;
     border: 1px solid #979797;
     background-color: #FFFFFF;
     overflow-x: hidden;    
     padding: 10px;
     gap: 16px;
     margin-bottom: 20px;
     margin-top: 20px;
}
.searchhead{
    display: flex;
    justify-content: space-between;
}
.listContent{
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}
.list{
    flex: 1 1 20%;
    max-width: 20%;
}
.spacer {
    flex: 1 1 auto;
}
.head{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.subhead{
    display: flex;
    
}
.orgname{
    border-bottom: 1px solid #e60000;
    color: #e60000;
    cursor: pointer;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
}
.status{
    border-radius: 6px;
    color: #333;
     /* background-color: #EB9700; */
    font-family: "VodafoneRg-Bold";
    font-size: 14px;
    /* margin-left: 10px;
    margin-top: 8px; */
    margin: auto 8px;
    padding: 0px 16px;
    height: 24px !important;
}
.pending{
    background-color: #FFE1AB;
    border: 2px solid #F4B035
  }
  .rejected{
    background-color: #FFAFAF;
    border: 2px solid #E64040
  }
  .approved{
    background-color: #C7FFE6;
    border: 2px solid #80D8B0
  }
.gotologo{
    height: 28px;
    width: 28px;
    /* margin-top: 5px; */
    cursor: pointer;
}
.label{
    color: #666666;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
}
.value{
    color: #000000;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 700;
}
.duedate{
    color: #333333;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    background-color: #FFEECC;
    border-radius: 4px;
    margin-top: 20px;
    padding: 2px 5px;
}
.search{
    margin-top: -25px;
}

.Emptydiv{
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    background-color: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #979797;
    display: flex;
}

.newcomment{
  width: 24px;
  height: 24px;
}
.notificationmain{
    position: relative;
    display: flex;
}
.notificationicon{
    margin-top: -14px;
    margin-left: -6px;
}
.indicator{
    display: flex;
}
.indicators{
    display: flex;
    justify-content: end;
  }
  .exclated{
  padding: 4px 12px 4px 12px;
  gap: 10px;
  border-radius: 4px;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 18.37px;
  text-align: left;
  margin-right: 10px;
  background-color: #FFDADB;
  }

  .modifiedOn{
    color: #666666;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    margin: auto 10px;
  }