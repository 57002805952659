/*---------------*/
.wrapperContainer{
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    flex: 1;
}
.hrLine{
    border-bottom: 0.4px solid #666666;
    width: 100%;
}
/*------Radio Button CSS-----------------*/
.radioContainer{
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  .radioGroup{
    display: flex;
    gap:40px
  }
  .radioSectionLabel,.FailuerTypeDropdownLabel{
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    color: #666666;
    font-weight: 400;
    flex: 1;
  }
  .FailuerTypeDropdown{
    flex: 2;
  }
  .redioField{
    display: flex;
    align-items: center;
    gap: 8px;
    flex:1
}
.radioLabel {
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-weight: 400;
    color: #333333; 
}

/*-------------Tooltip----------------*/
.tooltipBody{
    background: #f5f3f3;
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    color: #333333;
    max-width: 200px;
    padding: 8px 8px;
    border-radius: 8px;
}
.popoverContainer{
    z-index:103;
}
/*---------------Input form-----------------------*/
.input {
    margin-top: 0px !important;
    font-size: 20px;
    color: #333;
  }
  .lable {
    padding: 0px !important;
    margin-bottom: 8px;
    font-size: 18px;
    color: #666;
  }
  .previewText{
    flex:1;
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
    font-weight: 400;
    color: #333333; 
}
  .textLimitInfo{
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    color: #666;
    display: flex;
    align-items: end;
    justify-content: end;
    height: 0px;
    position: relative;
    top: 20px;
  }
  .Field{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    word-break: break-all;
  }
  .ImagePreviewField{
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
  }
  .expandedField{
    grid-column: span 2 / span 2;
  }
  
  .inputError,
  .selectError {
    border-color: #CD3500 !important
  }
  .previewFailureSelectText{
    flex:1;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-weight: 400;
    color: #333333; 
  }
  .previewFaText{
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    color: #333333;
  }
  /*------Form---------*/
  .form{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
    row-gap: 32px;
}
.formTitle{
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    color: #333333;
    margin-bottom: 16px;
}
.footer {
    display: flex;
    flex: 1;
    justify-content: space-between;
    background: #fff;
    padding-top: 16px;
    margin-top: 16px;
    position: relative;
    align-items: end;
    justify-content: end;
    justify-content: space-between;
  }
  .closedetalpopup{
    justify-content: flex-end;
  }
  .footer::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #666666;
    left: 0;
    bottom: 64px;
    transform: scaleX(1.8);
  }
 
  .btn{
    margin: inherit !important;
  }
  /*---------File Listing---------*/
  
.thumb {
    display: flex; 
    flex-direction: row;
    width: 256px;
    height: 48px;
    border-radius: 6px;
    border: solid 1px #979797;
    padding: 12px;
   
  }
  
  .thumbInner {
   display: flex;
   flex-direction: row;
   flex: 1;
   align-items: center;
  }
 
  img.preview {   
    width: 36px;
    height: 36px;
    margin-left: -7px;
  }

  .fileName {
    flex: 2;
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    color: #333333;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
/*---------------------------------*/