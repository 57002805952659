.Container {
    /* justify-content: center; */
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-image: url("../../assets/images/login-bg.png");  
    background-size : 100% 100%; 
    background-repeat: no-repeat;
    margin-bottom: 30px;
    height:100vh;
  }

.SubContainer {
    margin-top: 30px;
    width: 558px;
    background-color: #FFFFFF;
    padding: 20px 20px;
  }
.messageContainer {
    text-align: center;
    color: #000000;
    font-size: 24px;
    font-family: "VodafoneRg-Regular";
}

.headerText {
    font-family: "VodafoneRg-Regular";
    font-size: 56px;
    font-style: normal;
    text-align: center;
    color: #333333;
}

.headerLogo {
    text-align: center;
}

.logo {
    margin-top: 10px;
    height: 40px;
    width: 40px;
    margin-left: 16px;
}

  