.buttonContainer {
  display: flex;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  color: #666666;
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  font-style: normal;
}
.backButton {
  background-color: #666666;
}
.labelcomment {
  margin-bottom: 6px;
}
.separator {
  margin-top: 16px;
  margin-bottom: 16px;
  border-color: 1px solid #666666;
  width: 100%;
  margin-left: -24px;
  margin-right: 0%;
  opacity: 1 !important;
  transform: scaleX(1.5);
}
.commentValidation {
  color: #666666;
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  text-align: right;
}
.comment {
  height: 28px;
  width: 28px;
  cursor: pointer;
}
.addcomment {
  height: 46px;
  width: 100%;
  border: 1px solid #979797;
  border-radius: 6px;
  font-size: 20px;
  padding: 0px 10px;
  color: #ffffff;
  background-color: #666666;
  font-family: "VodafoneRg-Regular";
}
.sideNavStyle {
  width: 60% !important;
}
.notification {
  height: 12px;
  width: 12px;
  background-color: #bd0000;
}
.labelcompLevel {
  font-size: 16px;
}
.additionalStyle {
  font-size: 18px;
}

.form {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 88px - 40px);
}

.footer {
  align-items: end;
  justify-content: end;
}

.provideinfobtn{
  width: 194px;
  background-color: #666666;
  color: #FFFFFF;
}
.notresolved{
  width: 148px;
  color: #000000;
  background-color: #FFFFFF;
}

.accepted{
  width: 148px;
  background-color: #E60000;
  color: #FFFFFF;
}
.commonbtncss{
  height: 40px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #979797;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  font-family: "VodafoneRg-Regular";
}