.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    /* height: 430px; */
    min-height: calc(100vh - 56px - 56px);
  }

.SubContainer {
    padding: 50px;
    width: 558px;
    border: 1px solid  #979797;
    background-color: #FFFFFF;
    box-shadow: 0 0 5px 1px #979797;
  }
.messageContainer {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    color: #000000;
    font-size: 24px;
    font-family: "VodafoneRg-Regular";
}

.headerText {
    font-family: "VodafoneRg-Regular";
    font-size: 56px;
    font-style: normal;
    text-align: center;
    color: #333333;
}

.headerLogo {
    text-align: center;
}

.logo {
    margin-top: 10px;
    height: 40px;
    width: 40px;
    margin-left: 16px;
}

  