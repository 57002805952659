.container {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 6px;
}

.subContainer {
  /* display: flex;
  justify-content: space-between; */
}

.childContainer{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  /* padding-bottom: 30px;
  padding-top: 20px; */
  margin: 20px 0;
  align-items: center;
}
.header {
  font-family: "VodafoneRg-Regular";
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  color: #333333;
  /* flex: 1; */
}
.subHeader {
  font-family: "VodafoneRg-Regular";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #333333;
}
.compWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  /* align-items: flex-start; */

  align-items: center;
}

.sublabel {
  border-radius: 6px;
  padding: 8.5px 16px;
  gap: 10px;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  color: #ffffff;
  background-color: #007c92;
  box-shadow: 1px 1px 8px 0px #00000014;
}

.disablelabel{
  border-radius: 6px;
  padding: 8.5px 16px;
  gap: 10px;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  color: #333333;
  border: 1px solid #979797;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 1px 1px 8px 0px #00000014;
}

.invoiceStatusLegends {
  display: flex;
}
.statusLegendsLabel {
  margin-right: 20px;
}
.statusLegendsIcon {
  border-radius: 100px;
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-bottom: -4px;
  margin-right: 5px;
  /* border: 1px solid #ACEDD0; */
}
.statusLegendsIconAmber {
  background-color: #FFEA96;
  border: 1px solid #F4D55B;
}
.statusLegendsIconGreen {
  /* background-color: #009900; */
  background-color: #ACEDD0;
  border: 1px solid #80D8B0;
}

.statusLegendsIconBlue {
  background-color: #D5F9FF;
  border: 1px solid #89DBE9;
}
.spanSpaceing {
  /* margin: 6px 10px 1px 10px; */
}
.customDataContent{
  display: flex;
  margin-left: 10px;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2%;
  /* margin: auto; */
}

.customDataContents{
  display: flex;
  margin-left: 10px;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2%;
  cursor: pointer;
}

.invoiceId {
  color: #e60000;
  text-decoration: underline;
  cursor: pointer;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2%;
}

.invoiceIds{
  color: #e60000;
  text-decoration: underline;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2%;
}

.organame {
  /* cursor: pointer; */
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2%;
}

.texttruncate{
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip > span {
  white-space: nowrap;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emptydiv{
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100px; */
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  background-color: #F4F4F4;
  border-radius: 8px;
  /* border: 1px solid #979797; */
  padding: 16px 24px;
  /* margin-bottom: 10px; */
  height: calc(100vh - 120px - 132px - 132px - 51px);
}

.filter{
  position: absolute;
  right: 0;
  bottom: -4px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.invoicedue{
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.downloadlogo{
  margin: auto 10px 7px 0px;
  cursor: pointer;
}

.disabledownloadlogo{
  margin: auto 10px 7px 0px;
  cursor: none;
  opacity: 0.5;
}

.orgname{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.monthparent{
  width: 210px;
}

.invoiceStatus {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 6px;
}

.statuslab{
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  font-size: 16px;
  line-height: 18.34px;
  color: #333333;
  margin-left: 10px;
}

.statusval{
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 18.37px;
  color: #333333;
  margin-left: 5px;
}

.statusLegend {
  margin-bottom: 5px;
}

.organisationName{
  width: 100%;
  position: relative;
}

.documentDate{
  width: 100%;
  position: relative;
}

.document{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-right: 10px;
}

/* -------------Progress Bar---------------- */

/* .progressbody{
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 8px;
  background-color: #FFFFFF;
  display: flex
;
}

.progressBarContainer {
  width: 100%;
  background-color: #F4F4F4;
  border-radius: 8px;
  overflow: hidden;
  margin: 8px 5px;
  position: relative;
}

.progressBar {
  height: 10px;
  background-color: #007C92;
  transition: width 0.4s ease;
  position: relative;
  border-radius: 8px;
}

.roundBall {
  position: absolute;
  top: 50%;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: #FFFFFF;
  border-radius: 50%;
  transform: translate(0%, -50%);
}

.progresstext{
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: auto;
  color: #333333;
}

.percentage{
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: auto;
  color: #333333;
} */

/* --------------------CircularProgressBar------------------- */
.progressContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F4F4F4;
  border-radius: 6px;
  height: calc(100vh - 120px - 132px - 132px - 51px);
}

.progressRing {
  transform: rotate(-90deg);
}

.progressRingCircle {
  transition: stroke-dashoffset 0.35s;
  transform-origin: 50% 50%;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.progressText {
  position: absolute;
  font-size: 1.5em;
  font-weight: bold;
  transform: translate(5%, -50%);
}

.loadingText {
  margin-top: 10px;
  font-size: 1.2em;
  color: #333;
}


/* ---------------------------------------- */

.filterdata{
  text-align: center;
  padding: 20px;
  font-family: "VodafoneRg-Regular";
  font-size: 20px;
}

.noinvoice{
  display: block;
  align-items: center;
  text-align: center;
}

.noinvoiceimage{
  width: 204px;
  height: 156.28px;
}

.noinvoicetext{
  text-align: center;
}

.custominput{
  position: relative;
  width: 300px;
}

.fincainfo{
  color: #333333;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  line-height: 18.37px;
  border-radius: 6px;
  background-color: #DCDCDC;
  /* margin-bottom: 10px; */
  margin-top: 20px;
  gap: 5px;
  padding: 4px 8px;
}

.circleinfo{
  height: 20px;
  width: 20px;
  margin-right: 5px;
  cursor: pointer;
  margin-bottom: 2px;
}

.wrapper{
  position: relative;
}
.sorting{
  position: absolute;
  right: 0;
  bottom: -4px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.dropdownopen{
  text-decoration: underline;
  color: #E60000;
  cursor: pointer;
}