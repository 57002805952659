/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  /* width: 60% !important; */
  position: fixed; /* Stay in place */
  z-index: 101; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.1s; /* 0.5 second transition effect to slide in the sidenav */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
}
.sidemenuContainer {
  padding: 24px;
  border-top: 1px solid #666666;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  font-size: 36px;
  margin-left: 50px;
  color: #818181;
  cursor: pointer;
  text-decoration: none;
  cursor: pointer;
}

.open {
  width: 60% ;
}

.header {
  display: flex;
  padding: 24px 24px 16px 24px;
}

.headerTxt {
  flex: 1;
  font-size: 24px;
  height: 48px;
  color: #333333;
  font-family: "VodafoneRg-Regular";
}

.closeBtn {
  appearance: unset;
  background-color: transparent;
  border: none;
  height: 24px;
  width: 24px;
  padding-block: 0;
  padding-inline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(4px);
  z-index: 100;
}
