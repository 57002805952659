.commentContainer {
    display: flex;
    padding: 20px 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #666666;
    background: #FFF;
    margin-bottom: 24px;
}

.commentHeaderText {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.commentHeaderText>div {
    color: #666;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    padding-right: 18px;
    word-wrap: break-word;   
}
.commentHeaderText>div:nth-child(1){
    border-right: 2px solid #EBEBEB;
}
.commentText {
    color: #333333;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-style: normal;    
}
.attachmentSection {
    width: 100%;
}

  
  .requestorDetailsDiv {
    /* display: flex; */
    flex-direction: row;
    flex:1;
  }
  .requestorvalue{
    overflow-wrap:anywhere;
    font-weight: 700;
    font-size: 20px;
    color:#0D0D0D;
    font-family: "VodafoneRg-Regular";
  }
  
  .topSectionDiv
  {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
  
  }
  .bottomSectionDiv {
    display: flex;
    flex-wrap: wrap;
  }
  .bottomSectionDiv > div {
   flex:50%;
   margin-bottom: 20px;
  }
  
  .requestorDetailsDiv span,
  .serviceDetailDiv span {
    color: #0D0D0D;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    color:#666666;
    font-style: normal;
  }
  .servcelabel{
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    color:#666666;
    
  }
  .servcevalue{
    font-weight: 700;
    font-size: 20px;
    color:#0D0D0D;
    font-family: "VodafoneRg-Regular";
  }
  .serviceDetailDiv span:nth-child(2n),
  .requestorDetailsDiv span:nth-child(2n) {
    font-weight: 700;
    font-size: 18px;
    color:#0D0D0D;
  }
  .servceDetailDiv{
   justify-content: space-between;
  }
  
   
