.searchContainer {
    margin-top: 25px;
    display: flex;
    /* border: 1px solid #999999; */
    border-radius: 6px;
    width: fit-content;
    /* height: 47px; */
}

.input {
    outline: none;
    width: 345px;
    border: none;
    padding-left: 10px;
    border-radius: 6px 0px 0px 6px;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    border: 1px solid #999999;
}

input[type="search"]::-webkit-search-cancel-button {
    display: none;
  }

.imageIcon {
    /* height: 46px; */
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
}

/* search for forms only */

.inputform {
    outline: none;
    width: 100%;
    border-radius: 6px;
    height: 48px;
    padding-left: 10px;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    border: 1px solid #979797;
}

