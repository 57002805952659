.collapsibleContainer {
  border: 1px solid #979797;
  border-radius: 6px;
  padding: 10px;
  background-color: #FFFFFF;
  margin-bottom: 20px;
}

.collapsible {
  background-color: #FFFFFF;
  border-radius: 6px;
  color: rgb(0, 0, 0);
}

.content {
  border-radius: 6px;
  display: none;
  background-color: #FFFFFF;
  color: rgb(0, 0, 0);
  transition: max-height 0.3s ease-in-out;
}

.content.active {
  display: block;
}

.open {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #FFFFFF;
  color: #666666;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
}

.close {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #FFFFFF;
  color: #666666;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
}

.arrowdown {
  position: absolute;
  top: 1px;
  left: 80px;
}

.arrowup {
  position: absolute;
  left: 70px;
  top: 1px;
}

.titleContainer {
  display: flex;
}

.contentInner {
  display: flex;
}

.headservicename {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #E6E6E6;
  padding-bottom: 5px;
}

.statusContainer {
  margin: auto 0;
}

.servicetitle {
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 5px;
  /* text-decoration: underline; */
  border-bottom: 1px solid #E60000;
  color: #E60000;
  cursor: pointer;
}

.serviceregion {
  font-family: "VodafoneRg-Regular";
  font-size: 12px;
  color: #333333;
}

.serviceTitleContainer {
  /* flex: 1; */
  display: flex;
}

.serviceStatus {
  font-family: "VodafoneRg-Bold";
  font-size: 14px;
  line-height: 16.04px;
  text-align: right;
  color: #333;
  border-radius: 6px;
  padding: 5px 14px;
  /* background-color: #009900; */
  gap: 10px;
  margin-left: 10px;
  display: grid;
  /* height: 24px; */
}

.liveDetailsDiv {
  flex: 1;
  padding-top: 10px;
}

.liveDetailsviewmore {
  flex: 1;
}

.liveDetaillabel {
  display: block;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 18.34px;
  color: #666666;
}

.liveDetailsviewmore span {
  color: #666666;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
}

.liveDetailval {
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  font-size: 16px;
  line-height: 18.34px;
  color: #333333;
}

.liveDetailsviewmore {
  color: #000000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
}

.link {
  color: #E60000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
}


/* livesearchBar */
.livesearchBar {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.download {
  cursor: pointer;
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #999999;
  border-radius: 6px;
  font-size: 14px;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  height: 48px;
  width: 127px;
}
.downloadlogo{
  margin-left: 10px;
  margin-bottom: 5px;
  height: 20px;
  width: 20px;
}

.searchLiveServices {
  width: 290px;
}

.emptyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  min-height: 95px;
  overflow-x: hidden;
  border-radius: 6px;
  border: 1px solid #979797;
  padding: 20px;
  gap: 16px;
  background-color: white;
}

.ammendIcon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.filterContainer {
  display: flex;
  justify-content: end;
  flex: 1;
  margin-right: 10px;
}
.selectedFilters{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.selectedItem{
  background: #EBEBEB;
  border-radius: 26px;
  border: 1px solid #979797;
  margin-right: 16px;
  height: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 8px 12px 8px 12px;
  margin-bottom: 16px;
}
.label{
  font-family: "VodafoneRg-Bold";
  font-size: 14px;
  margin-right: 8px;
}
.value{
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
}
.selectedFilterContainer {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
}
.formikBtnSubmit {
  color: #fff;
  background-color: rgb(82, 81, 81);
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  flex: 1;
  height: 40px;
}

.formikBtnContainer {
  display: flex;
  justify-content: space-between;
  /* padding: 5px; */
  gap:10px;
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  font-weight: 700;
}

.formikBtnClear{
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  flex: 1;
  height: 40px;
  border : solid 1px #999999;
  background-color: #FFFFFF;
}
.fieldContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.inputError,
.selectError {
  border-color: #CD3500 !important
}
.applyFilterBtn {
  height: 48px !important;
  margin-top: 2px;
  width: 127px;
}
.labelShowFilter {
  color: #000000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  margin-top:4px;
}

.valanLabel{
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 18.34px;
  color: #666666;
}

.valanVal{
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  font-size: 16px;
  line-height: 18.34px;
  color: #333333;
}

.liveDetail{
  height: calc(100vh - 56px - 56px - 56px - 50px);
  overflow: auto;
  padding: 0 5px;
}

.parent{
  display: flex;
  gap: 30px;
  margin-bottom: 10px;
}

.sidelabel{
  padding-bottom: 2px;
  font-size: 18px;
  font-family: "VodafoneRg-Regular";
  color: #666666;
}

.sidedetail{
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
  padding-bottom: 8px;
}

.separator {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-top: 16px;
  margin-bottom: 16px;
  border-color: #666;
  transform: scaleX(1.5);
  opacity: 100;
}

.amendservice{
  /* height: 40px; */
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #979797;
  gap: 10px;
  background-color: #E60000;
  color: #FFFFFF;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  /* margin-top: 18px; */
  /* float: right; */
  padding: 8px 30px;
}

.headertxt{
  display: flex;
}

.container{
  display: flex;
  justify-content: space-between;
}

.Backbtn{
  display: flex;
  background-color: #FFFFFF;
  color: #000000;
  border-radius: 6px;
  height: 48px;
  border: 1px solid #999999;
  width: 148px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 12px 16px 12px 16px;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  cursor: pointer;
  margin: auto auto auto 0px;
}
.sideNavStyle{
  width: 585px;
}
.moduledescription{
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  color: #333333;
  background-color: #DCDCDC;
  /* height: 36px; */
  padding: 8px 8px;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.marginfromtop{
  margin-top: 24px;
}

.descriptioncircle{
  padding: 0px 5px;
}
.noShutdown{
  background-color: #00990059;
  border: 2px solid #547F54
}
.shutdown{
  background-color: #C4C4C4;
  border: 2px solid #747474
}
.valueIsMissing{
  background-color: #FFD3D3;
  border: 2px solid #FF6F6F
}