/*CSS*/
.headContainer {
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  background-color: #f5f5f5;
}

.vfuser {
  font-family: "VodafoneRg-Regular";
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  align-items: center;
}

.addUserBtn {
  width: 221px;
  height: 48px;
  /* padding: 12px 20px 12px 20px; */
  font-size: 20px;
  border-radius: 6px;
  margin-top: 24px;
  color: #ffffff;
  border: 1px solid #999999;
  font-family: "VodafoneRg-Regular";
  background-color: #666666;
}
.CustomerContainer {
   display: grid;
  grid-template-columns: repeat(4, 1fr); 
  
  margin-top: 20px;
  min-height: 95px;
  overflow-x: hidden;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #979797;
  border-radius: 6px;
}
.requestDetail{
  background-color:#EBEBEB;
  padding: 3px 6px;
  margin: 15px 0px 0px 0px;
  border-radius: 4px;
  color: #333;
  font-family: "VodafoneRg-Bold";
  font-size: 14px;
}
.CustomerContainers {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); */
  
  margin-top: 20px;
  min-height: 95px;
  overflow-x: hidden;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #979797;
  border-radius: 6px;
}
.expanded {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 5 / span 5;
  gap: 10px;
}

.CustomerList {
  flex: 1;
  max-width: 20%;
  min-width: 20%;
  color: #000000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
}
.checkboxipx{
  margin-right: 40px;
}
.Customerbox{
  display: flex;
  margin-top: 10px;
  position: relative;
}
.spanlabel {
  color: #666666;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
}
.tabel{
  margin-top: 20px;
}
.editrole{
  height: 20px;
  cursor: pointer;
  width: 20px;
}
.primary{
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-bottom: 4px;
  margin-right: 4px;
}
.CustomerList p {
  color: #000000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  margin: 0;
  font-weight: 700
}
/* switch */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.spanSwitch {
  margin: 0px 5px;
}
/* SearchList */
.searchBar {
  margin-bottom: 0px;
  margin-top: 0%;
}

.searchList {
  width: 340px;
}
.emptyContainer{
  display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    min-height: 95px;
    overflow-x: hidden;
    border-radius: 6px;
    border: 1px solid #979797;
    padding: 20px;
    gap: 16px;
    background-color: white;
}

.container {
  overflow-x: hidden;
  border-radius: 6px;
  border: 1px solid #979797; /* 1px border */
  padding: 20px;
  gap: 16px; /* Gap between flex items */
  background-color: white;
}
.headData {
  display: flex;
  flex-direction: column;
}

.addButton {
  width: 186px;
  height: 48px;
  border-radius: 6px;
  border: 1px solid #999999;
  font-family: "VodafoneRg-Regular";
  background-color: #666666;
  color: #ffffff;
  padding: 20px, 20px, 20px, 20px;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 42px;
}
.text {
  /* padding-top: 14px; */
  font-family: "VodafoneRg-Regular";
  color: #333333;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.alignbtn{
  display: flex;
  align-items: center;
}
.alignbtns{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 20px;
}
.textuser {
  font-family: "VodafoneRg-Regular";
  color: #333333;
  font-weight: 700;
  margin-top: 18px;
  margin-bottom: 14px;
  /* padding-top: 10px; */
  font-size: 20px;
}
.textCustomer {
  font-family: "VodafoneRg-Regular";
  color: #333333;
  font-weight: 700;
  /* margin-top: 10px; */
  /* height:24px; */
}
.customer {
  display: flex;
  flex-direction: row;
  /* margin-bottom: 10px; */
  margin-top: 20px;
  font-size: 20px;
}
.icon {
  height: 20px;
  margin: auto 0;
  cursor: pointer;
  margin-right: 10px;
}
.checkboxdiv{
  display: flex;
  margin-top: 10px;
}
.headnote{
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  color: #333333;
  background-color: #FFEBCD;
  border-radius: 6px;
  height: 28px;
  display: flex; 
  align-items: center;
  margin-top: 10px;
  padding-left: 5px;
}

.checkboxmain{}

.detailsContainer {
  display: flex;
  flex-direction: column;
}

.detailsContainer[data-vf-user="true"] {
  flex-direction: column-reverse;
}

.transparentBtn {
  background-color: transparent;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  border: none;
}

.moduleAssignedHeader {
  font-family: "VodafoneRg-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.expanded {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 5 / span 5;
  gap: 10px;
}
.transparentBtn {
  background-color: transparent;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  border: none;
}
.radioField {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.radioInput {
  width: 20px;
  height: 20px;
  accent-color: #00697c;
}
.radioLabel {
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  color: #0d0d0d;
}

.sectionContainer{
  display: flex;
  margin-bottom: 16px;
}
.sectionTitle{
  font-family: "VodafoneRg-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-right: 16px;
}
.Icon{
  height: 19px;
  width: 19px;
  cursor: pointer;
}
.loaderContainer{
  width: 20px;
  height: 20px;
}
.active{
  padding-top: 20px;
}
.sharedEmailContainer {
  display: flex;
  flex:1;
  margin-top: 20px;
  min-height: 95px;
  overflow-x: hidden;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #979797;
  border-radius: 6px;
}

.borderbottom{
  border-bottom: 2px solid #E6E6E6;
}
.linkdiv{
  color: #E60000;
  text-decoration: underline #E60000;
  /* cursor: pointer; */
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  text-align: end;
  /* display: flex; */
  /* align-items: center; */
  /* margin-top: 30px; */
  /* position: absolute; */
  right: 0;
}

.raisedreq{
  font-family: "VodafoneRg-Regular";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}
.primarylabel{
font-family: "VodafoneRg-Regular";
text-align: right;
font-size: 18px;
font-weight: 500;
line-height: 20px;
color: #333333;
margin-top: 10px;
}
.loaderStyle{
  height: 30px;
  width: 30px;
}
.input {
  border-radius: 6px;
  border: 1px solid #979797;
  width: 125px;
  color: #000000;
  font-size: 18px;
  font-family: "VodafoneRg-Regular";
  padding: 2px 6px;
}
.inputvalue{
  color: #000000;
  font-size: 18px;
  font-family: "VodafoneRg-Regular";
  position: absolute;
  bottom: 0;
  font-weight: 700;
  margin-left: -113px;
}
.editbtn{
  height: 18px;
  cursor: pointer;
  width: 18px;
  margin-bottom: 6px;
  margin-left: 5px;
}
.loaderStyle{
  position: absolute;
  top: 0;
  margin-left: 5px;
  width: 30px;
  height: 30px;
}
.submitbtn{
  position: absolute;
  top: 0;
  margin-left: 5px;
}
.disabledButton {
  cursor: not-allowed;
}
.saveButton{
  border: none;
  background: none;
  padding: 0;
}


/* ----------------before --------- */
.pointer {
  cursor: pointer;
}

.notAllowed {
  cursor: not-allowed;
}
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #7e7e7e;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;

  /* content: "\2713"; */
}

input:checked + .slider {
  background-color: #00697c;
  
}

input:checked + .slider:before {
  transform: translateX(26px);
  /* content: "\2713"; */
}

/* Rounded sliders */
.slider {
  border-radius: 34px;
}

.slider:before {
  border-radius: 50%;
}


.loaderStyleswitch{
  height: 30px;
  width: 30px;
  padding: 0px !important;
  margin: 0px !important;
}

/* --------------Finca Popup ----------------------- */
.alertmessage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.alertcontent {
  background: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width:  500px
}

.closepopup{
  cursor: pointer;
}

.close{
  width: 18px;
  float: left;
  height: 18px;
}

.tableparent{
  max-height: 200px;
  overflow-y: auto;
}

.fincaHeader{
  font-family: 'VodafoneRg-Regular';
  font-weight: 700;
  font-size: 16px;
  color: #333333 !important;
}

.fincaVal{
  font-family: 'VodafoneRg-Regular';
  font-weight: 400;
  font-size: 16px;
  color: #333333 !important;
}
.linkgoto{
  color: #E60000;
  text-decoration: underline #E60000;
  cursor: pointer;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  text-align: end;
  /* display: flex; */
  /* align-items: center; */
  /* margin-top: 30px; */
  /* position: absolute; */
  right: 0;
}