.fieldContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .fieldContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .input {
    width: "auto";
    margin-bottom: 0px;
  }
  
  .inputError,
  .selectError {
    border-color: #CD3500 !important
  }
  
  .label {
    font-family: "VodafoneRg-Regular";
    margin-bottom: 10px;
    color: #100045;
    margin-left: 8px;
    font-size: 18px;
    cursor: pointer;
  }
  .checkboxs{
    cursor: pointer;
  }
  .submit {
    align-self: center;
}

.checkboxalign{
  display: flex;
  height: 24px;
}
.separator {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-top: 16px;
  margin-bottom: 16px !important;
  border-color: #666;
  transform: scaleX(1.5);
  opacity: 100;
}
.buttonContainer {
  display: flex;
}


.topSectionDiv
{
  display: flex;
  width: 100%;
  padding-bottom: 20px;

}
.bottomSectionDiv {
  display: flex;
  flex-wrap: wrap;
}
.bottomSectionDiv > div {
 flex:50%;
 margin-bottom: 10px;
}

.requestorDetailsDiv span,
.serviceDetailDiv span {
  color: #0D0D0D;
  font-family: "VodafoneRg-Regular";
  font-size: 18px;
  color:#666666;
  font-style: normal;
}
.servcelabel{
  font-family: "VodafoneRg-Regular";
  font-size: 18px;
  color:#666666;
  
}
.servcevalue{
  /* font-weight: 700; */
  font-size: 20px;
  color:#0D0D0D;
  font-family: "VodafoneRg-Regular";
}
.serviceDetailDiv span:nth-child(2n),
.requestorDetailsDiv span:nth-child(2n) {
  
  font-size: 18px;
  color:#0D0D0D;
}
.servceDetailDiv{
 justify-content: space-between;
}

.requestorDetailsDiv {
  flex-direction: row;
  flex:1;
}
.requestorvalue{
  overflow-wrap:anywhere; 
  font-size: 20px;
  color:#0D0D0D;
  font-family: "VodafoneRg-Regular";
}
.btnLoader {
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
}
.commentValidation {
  color:#666666;
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  text-align: right;
  margin-top: -18px;
}
.Select.is-disabled > .Select-control {
  cursor: not-allowed; 
}

.amendServCont{
  height: calc(100vh - 124px - 94px);
  overflow: auto;
  padding: 7px 5px;
}
