.container{
    border-radius: 6px;
    border: 1px solid #979797;
    background-color: #FFFFFF;
    overflow-x: hidden;    
    padding: 10px;
    gap: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
    /* height: 180px; */
}
.listContent{
    display: flex;
    flex-direction: row;
}
.actionContent{
    display: flex;
    justify-content:space-between;
}
.list{
    flex: 1;
    max-width: 20%;
}
.label{
    color: #666666;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    padding-bottom: 2px;
    margin-top: 10px;
}
.labele{
    padding-top: 2px;
    color: #0E0E0E;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
     margin-top: 10px;
}
.value{
    color: #000000;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}
.valueadditionalinfo{
  color: #000000;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
.backbutton{
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-bottom: 4px;
    margin-right: 8px;
  }
  .back{
      margin: 16px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
  }
  .id{
    color: #333333;
    font-weight: 700;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
  }
.alert{
    margin-left: 20px;
    border-radius: 6px;
    font-size: 14px;
    font-family: "VodafoneRg-Regular";
    color: #000000;
    background-color: #FFEECC;
    padding: 3px;
}
.tadiglabel{
    color: #666666;
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
}
.tadigvalue{
    color: #000000;
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
    font-weight: 700;
}
.feedbackcontent{
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  font-weight: 700;
  line-height: 18.34px;
  text-align: left;
  color: #0D0D0D;
  margin: auto 0;
  width: 55%;
}
/*   
  .feedbackInfo{
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
  } */
  .containerapproval{
    border-radius: 6px;
    border: 1px solid #999999;
    overflow-x: hidden;    
    padding: 25px 10px;
    gap: 16px;
    margin-top: 20px;
    background-color: #FFFFFF;
    /* height: 80px; */
}
  .approvalq{
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
    display: flex;
    margin-top: 10px;
  }
  .feedbackbtn{
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  .comment{
    height: 100px;
    border: 1px solid #979797;
    border-radius: 6px;
    background-color: #FFFFFF;
    font-size: 20px;
    text-align: center;
    padding-top: 30px;
  }
  .listhead{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .add{
    color: #333333;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
  }
  .addform{
    color: #E60000;
    font-family: "VodafoneRg-Regular";
    cursor: pointer;
    border-bottom: 1px solid #E60000;
  }
.requestDetail{
  background-color: #EBEBEB;
  padding: 3px 6px;
  margin: 5px 0px;
  border-radius: 4px;
  color: #333;
  font-family: "VodafoneRg-Bold";
  font-size: 14px;
}
.commentContainer {
  border: 1px solid #979797;
  border-radius: 6px;
  padding: 10px;
  background-color: #ffffff;
  margin: 10px 0px;
}

.initalshead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}
.initatname {
  font-weight: 700;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
}

.initaltext {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  color: #333333;
  font-family: "VodafoneRg-Regular";
  margin: 20px 0px;
}
.userp{
  display: flex;
  align-items: center;
}
.inital {
  height: 36px;
  width: 36px;
  font-size: 16px;
  color: #ffffff;
  border-radius: 25px;
  background-color: #dc2b2b;
  line-height: 36px;
  text-align: center;
  margin-right: 8px;
  font-family: "VodafoneRg-Regular";
}
.servicedesk{
  height: 36px;
  width: 36px;
  font-size: 16px;
  color: #ffffff;
  border-radius: 25px;
  background-color: #5E2750;
  line-height: 36px;
  margin-right: 8px;
  font-family: "VodafoneRg-Regular";
  text-align: center;
}

.attachments{
  color: #666666;
  font-size: 16px;
  padding-right: 20px;
  font-family: "VodafoneRg-Regular";
}

.date {
  color: #000000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  padding-left: 3px;
  word-wrap: break-word;
}

.commenttext {
  font-size: 18px;
  font-family: "VodafoneRg-Regular";
  color: #333333;
  margin-bottom: 10px;
}

.viewMore {
  color: #e60000;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  cursor: pointer;
  border-bottom: 1px solid #e60000;
  font-weight: normal;
  margin-left: 10px;
}

.emptydiv{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  background-color: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #979797;
}

.status{
  border-radius: 6px;
  color: #333;
   /* background-color: #EB9700; */
  font-family: "VodafoneRg-Bold";
  font-size: 14px;
  margin-left: 10px;
  margin-top: 8px;
  padding: 3px 16px;
  height: 24px !important;
}
.pending{
  background-color: #FFE1AB;
  border: 2px solid #F4B035
}
.rejected{
  background-color: #FFAFAF;
  border: 2px solid #E64040
}
.approved{
  background-color: #C7FFE6;
  border: 2px solid #80D8B0
}


.borderbottom{
  border-bottom: 2px solid #E6E6E6;
}

.viewmore{
  display: flex;
  justify-content: flex-end;
}

.parent{
  /* display: flex; */
  display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px 0;
}

.attachment {
  align-items: center;
  border: 1px solid #666;
  border-radius: 6px 6px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  min-height: 40px;
  width: 250px;
  padding: 5px 0px 5px 0px;
}

.attachlogo {
  display: flex;
  height: 36px;
  width: 36px;
  cursor: pointer;
  /* margin-left: 10px; */
}

.filename {
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  margin-top: 1px;
  font-size: 16px;
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.download{
  cursor: pointer;
  height: 26px;
  margin-right: 10px;
  width: 26px;
}

.exclated{
  padding: 4px 7px;
  border-radius: 6px;
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.07px;
  background-color: #FFDADB;
  color: #000000;
  }

  .circleinfo{
    height: 14px;
    width: 14px;
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 1.5px;
  }
  .infotext{
    font-weight: 400;
    font-family: "VodafoneRg-Regular";
    /* margin-left: 5px; */
    font-size: 16px;
    line-height: 28px;
    color: #333333;
    /* background-color: #979797; */
    /* cursor: all-scroll; */
   margin-bottom: 10px;
    border: 1px solid #999999;
    border-radius: 6px;
    padding: 2px 10px;
    max-height: 88px;
    overflow: auto;
    width: 100%;
  }
  .infotexts{
    font-weight: 400;
    font-family: "VodafoneRg-Regular";
    /* margin-left: 5px; */
    font-size: 16px;
    line-height: 28px;
    color: #333333;
    margin-bottom: 10px;
    max-height: 88px;
    overflow: auto;
    width: 100%;
  }
  .info{
    font-weight: 400;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
  }