.imagecontainer {
  display: flex;
  /*flex: 1;*/
  flex-direction: column;
  align-items: center;
  position: relative;
}
.submitButton {
  position: absolute;
  left: 50% - 100px;
  top: 60px; /* top: 110px; */
  width: 240px; /* width: 320px; */
  height: 40px;/* height: 48px; */
  background-color: transparent;
  color: #FFFFFF;
  border: 2px solid #ffffff;
  Width: 223px;
  Height: 40px;
  border-radius: 6px;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
}

.bannerTxt {
  position: absolute;
  left: 50% - 100px;
  /* background-color: blue; */
  color: #FFFFFF;
  font-family: "VodafoneLt-Regular";
  font-size: 34px; /* font-size: 64px; */
}
.bannerText{
  position: absolute;
  left: 50% - 100px;
  top:30%;
  /* background-color: blue; */
  color: #FFFFFF;
  font-family: "VodafoneLt-Regular";
  font-size: 34px; /* font-size: 64px; */
}

.imagecontainer img {
  width: 100%;
  height: 120px; /* height: 192px; */
}
