.addButton{
    width: 221px;
    height: 48px;
    font-size: 20px;
    border-radius: 6px;
    color: #ffffff;
    border: 1px solid #999999;
    font-family: "VodafoneRg-Regular";
    background-color: #666666;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 88px - 40px);
  
    overflow: visible;
  }
  
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  
  .field {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: stretch;
  }
  
  .expanded {
    grid-column: span 2 / span 2;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 18.5px 16px;
    padding-bottom: 0px;
    margin-top: auto;
    /* position: fixed; */
    bottom: 0;
    right: 0;
    width: 100%;
    position: relative;
  }
  
  
  .footer::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #666666;
    left: 0;
    top: 0;
    transform: scaleX(1.8);
  }
  .inputError,
  .selectError {
    border-color: #CD3500 !important
  }
  
  
  /* -------------------------------- */
  
  
  
  .continue{
    border: none;
    color: #FFFFFF;
    Width:182px;
    Height:44px;
    border-radius: 6px;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    /* Padding:12px 16px 12px 16px; */
    cursor: pointer; 
    background-color: #666666;
    /* display: none; */
  }
  .stype{
  color: #666666;
  font-size: 20px;
  
  font-family: "VodafoneRg-Regular";
  align-items: center;
  }
  .additionalStyle{
    margin-top: 2px !important;
  }
  .buttonContainer {
    display: flex; 
  }
  .back{
    height: 48px;
    width: 148px;
  }
  .continuebutton{
    height: 48px;
    width: 148px;
    background-color: #E60000;
    color: #FFFFFF;
    border: none;
    border-radius: 6px;
  }
  .saveButton{
  
  }
  .separator {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-top: 10px;
  border-color: #666;
  transform: scaleX(1.5);
  opacity: 100;
  }
  .separatortop {
  margin-top: 25px;
  border-color: #666666;
  
  opacity: 100;
  }
  .submit{
    background-color: #E60000;
    color: #FFFFFF;
    border: none;
    Width:148px;
    Height:48px;
    border-radius: 6px;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    /* Padding:12px 16px 12px 16px; */
    cursor: pointer; 
  }
  .Buttons{
  margin-top: 400px;
  }
  .radioLabel {
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    padding-left: 5px;  
    margin-top: 0px;                   
  }
  .headsearch{
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #e6e6e6e6;
  }
  .orgnamehead{
    font-size: 24px;
    font-family: "VodafoneRg-Regular";
    color: #333333;
  }
  .containerlist{
    /* overflow-x: hidden; 
    overflow-y: auto;
    max-height: 450px; */
  }
  .radioInput {
    width: 20px;
    height: 20px;
    accent-color: #00697c;
  margin-top: 3px;
  }
  
  .radioField {
    display: flex;
    
    flex-direction: row;
  
  
  }
  .radiostyle{
    margin-right: 240px;
  }
  .radiodiv{
    display: flex;
  margin-top: 5px;
  margin-right: 50px;
  }
  .radiodivs{
    display: flex;
  margin-top: 5px;
  margin-right: 160px;
  }
  .wrapperContainer{
    display: flex;
    align-items: center;
    flex: 1;
  }
  .circle{
  margin-left: 20px;
  margin-top: 1px;
  }
  .circlered{
    /* margin-left: 5px; */
  margin-bottom: 3px;
      }
      .circleimg{
        margin-bottom: 3px;
      }
  .note{
  color: #666666;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  margin-bottom: 10px;
  }
  /* ivc */
  .buttonContainer {
    display: flex;
  }
  
  .fieldWrapper {
    display: flex;
    flex-direction: column;
    color: #666666; 
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    font-style: normal; 
  }
  .backButton {
    background-color: #666666;
  }
  .labelcomment{
    margin-bottom: 6px;
  }
  .separator {
    margin-top: 16px;
    margin-bottom: 16px;
    border-color: 1px solid #666666;
    width: 100%;
    margin-left: -24px;
    opacity: 1 !important;
  }
  
  .commentValidation {
    color:#666666;
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    text-align: right;
  }
  .textarea{
  margin-top: 25px;
  }
  
  /* IPX */
  .dropdowngrid{
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  }
  .dropdownwidths{
  padding-right: 48px;
  }
  .dropdownwidth{
  flex: 1;
  
  }
  .maintextbox{
  display: flex;
  }
  .textbox{
  flex: 1;
  margin-bottom: 25px;
  margin-top: 5px;
  margin-right: 24px;
  }
  .continueboth{
  display: flex;
  /* border: 2px solid #FFFFFF; */
  border: none;
  background-color: #E60000;
  color: #FFFFFF;
  Width:223px;
  Height:48px;
  border-radius: 6px;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  Padding:12px 16px 12px 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer; 
  }
  .sideNavStyle{
  width: 60% !important;
  }
  .obj{
  width: 100%;
  }
  .FailuerTypeDropdownLabel{
  font-family: "VodafoneRg-Regular";
  font-size: 18px;
  color: #666666;
  font-weight: 400;
  flex: 1;
  }
  .FailuerTypeDropdown{
  flex: 2;
  }
  .form{
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 88px - 180px);
  }
  /* footer */
  .footer {
  align-items: end;
  justify-content: end;
  }
  /* component level  */
  .labelcompLevel{
  font-size: 18px;
  font-family: "VodafoneRg-Regular";
  color: #333333 ;
  }
  .additionalStyle{
    margin-top: 3px !important;
  }
  
  
  
  .fieldWrapper {
    display: flex;
    flex-direction: column;
    color: #666666; 
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    font-style: normal; 
  }
  
  /* footer */
  
  
  .form{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 98px - 82px);
  }
  
  .footer {
    align-items: end;
    justify-content:space-between;
  }
  
  .separator {
    margin-top: 16px;
    margin-bottom: 16px;
    border-color: 1px solid #666666;
    width: 100%;
    margin-left: -24px;
    margin-right: 0%;
    opacity: 1 !important;
    transform: scaleX(1.5);
  }
  
  /* list */
  
  .containers {
    overflow-x: hidden;
    border-radius: 6px;
    border: 1px solid #999999; 
    padding: 10px;   /*padding: 20px; */
    gap: 16px; 
    background-color: white;
    margin-bottom: 20px;
  }
  .wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: normal;
    }
    .parentDiv{
        display: flex;
        flex:4;
        margin-bottom: 20px;
    }
    .headData{
        display: flex;
        flex-direction: column;
        flex:1;
        }
        .sub{
            color: #000000;
            font-size: 18px;
            font-family: "VodafoneRg-Regular";
            font-weight: 700;
        }
        .headSub{
            color: #666666;
            font-size: 18px;
            font-family: "VodafoneRg-Regular";
        }
        .customerNotFound{
            color: #151515;
            font-size: 18px;
            font-family: "VodafoneRg-Regular";
            background-color: #f3f3f3;
            height: 409px;
           align-content: center;
            justify-content: center;
            text-align: center;
            border-radius: 8px;
        }
        .containerheader{
          font-family: "VodafoneRg-Regular";
          color: #666666;
          font-size: 20px;
        }
        .headnote{
          font-family: "VodafoneRg-Regular";
          font-size: 14px;
          color: #333333;
          background-color: #FFEBCD;
          border-radius: 6px;
          height: 32px;
          display: flex; 
          align-items: center;
          margin-bottom: 20px;
          margin-top: 10px;
      }
      .infocircle{
          height: 20px;
          width: 20px;
          margin: 0px 5px 0px 10px;
      }
        .subhead{
          cursor: pointer;
        }
        .customerNotFoundtxt{
            color: #666666;
            font-size: 18px;
            font-family: "VodafoneRg-Regular";
   
        }
        .customerNottxt{
          color: #333333;
          font-size: 28px;
          font-family: "VodafoneRg-Regular";
  
      }
  .chackboxmain{
    display: flex;
    gap: 150px;
  }
  .select{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }
  
  .dropdowngrid{
   
  }
  .dropdownwidths{
    padding-right: 48px;
  }
  .selectwidth{
    flex: 1;
    
  }
  .chackboxtxt{
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    color: #666666;
    margin-top: 5px;
  
  }
  .ipxform{
    background-color: #00697C;
    color: #FFFFFF;
    border-radius: 4px;
    padding: 6px 8px;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 700;
  }
  .initalinfo{
    height: 361.42px;
    background-color: #f3f3f3;
    border-radius: 6px;
    border: 1px solid #000000;
  }
  .initalinfohead{
    color: #333333;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    margin: 30px 0px 30px 20px;
  }
  .initalinfotext{
    color: #333333;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
     height: 105px;
    background-color: #FFFFFF;
    border-radius: 6px;
    margin: 5px 20px 20px 80px;
    padding: 10px;
  }
  .info{
    color: #333333;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
  }
  
  .label{
    color: #333333;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
  }