.tooltipBody{
    background: #f5f3f3;
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    color: #333333;
    max-width: 200px;
    padding: 8px 8px;
    border-radius: 8px;
}
.popoverContainer{
    z-index:103;
}
.circle{
    height: 19px;
    width: 19px;
    margin-top: 5px;
    margin-left: 8px;
    margin-bottom: 5px;
      }