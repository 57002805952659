/*CSS*/

.headContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.container {
    overflow-x: hidden;
    border-radius: 6px;
    border: 1px solid #979797; 
    padding: 10px;   /*padding: 20px; */
    gap: 16px; 
    background-color: white;
    margin-bottom: 20px;
}
.twoservices {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Ensure items wrap if they exceed the container width */
}

.twoservice {
    margin-right: 10px; /* Add some space between items */
}
.parentDiv{
    display: flex;
    flex:4;
    margin-bottom: 0px;
}
.wrapper{
display: flex;
flex-direction: row;
justify-content: space-between;
align-self: normal;
border-top: 1px solid #E6E6E6;
padding-top: 10px;
}
/* .container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 25px;
    min-height: 95px;
    overflow-x: hidden;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #979797;
    border-radius: 6px;
} */
.containerheader {
    display: flex; 
    justify-content: space-between;
    align-items: top;
    margin-bottom: 10px; 
}
.headData{
display: flex;
flex-direction: column;
flex:1;
}
.search{
margin-top: -25px;
}
.sub{
    color: #000000;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
}
.headSub{
    color: #666666;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
}
.text{
font-family: 'VodafoneRg-Regular';
font-style: normal;
font-weight: 400;
font-size: 20px;
color: #E60000;
border-bottom: 1px solid #E60000;
}
.link{
    text-decoration: none;
}