.buttonContainer {
    display: flex;
  }
  
  .fieldWrapper {
    display: flex;
    flex-direction: column;
    color: #666666; 
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    font-style: normal; 
  }
  .backButton {
    background-color: #666666;
  }
  .labelcomment{
    margin-bottom: 6px;
  }
  .separator {
    margin-top: 16px;
    margin-bottom: 16px;
    border-color: 1px solid #666666;
    width: 100%;
    margin-left: -24px;
    margin-right: 0%;
    opacity: 1 !important;
    transform: scaleX(1.5);

  }
  .commentValidation {
    color:#666666;
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    text-align: right;
  }

  .addcomment{
    height: 46px;
    width: 100%;
    border: 1px solid #979797;
    border-radius: 6px;
    font-size: 20px;
    padding: 0px 10px;
    color: #FFFFFF;
    background-color: #666666;
    font-family: "VodafoneRg-Regular";
  }
  .sideNavStyle{
    width: 60% !important;
  }
  .notification{
    height: 12px;
    width: 12px;
    background-color: #BD0000;
  }
  .labelcompLevel{
    font-size: 18px;
  }
  .additionalStyle{
    font-size: 20px;
  }

  .form{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 148px - 70px);
  }

  .footer {
    align-items: end;
    justify-content: end;
  }

  .reject{
    width: 150px;
    background-color: #666666;
    color: #FFFFFF;
  }
  .approve{
    width: 150px;
    background-color: #E60000;
    color: #FFFFFF;
  }
  .comment{
    width: 150px;
    background-color: #ffffff;
    color: #000000;
    font-family: "VodafoneRg-Regular";
  }
  .commonbtncss{
    height: 40px;
    gap: 10px;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    font-family: "VodafoneRg-Regular";
    background-color: #666666;
    height: 44px;
    border: none;
    width: 148px;
    cursor: pointer;
  }
