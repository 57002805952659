@font-face {
  font-family: "VodafoneLt-Regular";
  src: url(./assets/fonts/VodafoneLt-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "VodafoneRg-Regular";
  src: url(./assets/fonts/VodafoneRg-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "VodafoneRg-Bold";
  src: url(./assets/fonts/VodafoneRg-Bold.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: "VodafoneLt-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*Google blue border to conatiner removed*/
.gm-style iframe + div { border:none!important; }

/*CSS For pop over Map tooltip*/
.gm-style-iw.gm-style-iw-c{
  padding:0px
}
.gm-style-iw-d{
  padding:0px;
  overflow: auto !important;
}
.gm-style-iw-chr{
  display: none !important;
}
/*-------End---------------*/