.container{
    display: flex;
    background-color:#F4F4F4;
    flex-direction: column;
    flex: 1;
    padding-bottom: 0px;
    margin-bottom: 24px;
    min-height:calc(100vh - 56px - 56px - 24px); /*  24px margin bottom ,56px header and 56px footer*/
    
}

.AppContainer{
  display: flex;
  background-color:#F4F4F4;
  flex-direction: column;
  flex: 1;
  min-height:100vh;
  
}

.subContainer{
    margin:0 24px !important
}
.loginContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-image: url("../../assets/images/login-bg.png");  
    background-size : 100% 100%; 
    background-repeat: no-repeat;
    height:100vh;
  }

  .loginSubContainer {
    margin:0 24px !important;
    width: 558px;
    background-color: #FFFFFF;
    min-height: 550px;
  }
  