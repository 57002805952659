.border{
    overflow: auto;
  border-radius: 6px;
  border: 1px solid #979797; 
}
.table {
    width: 100%;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;

    border-spacing: 0;
    border-collapse: collapse;
    border-style: hidden;

  }
  
  .thead,
  .tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
 
  }
  
  .tr {
    display: flex;
    width: 100%;
  }
  
  .td {
    border: 1px solid #E6E6E6;
    justify-content: center;
    flex: 1;
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
    color: #333333;
    height: 60px;
    display: flex;
    align-items: center;
    word-break: break-word;
    /* text-align: center; */
  }

  .tableheader{
    text-align: center;
  }
  
  .tdFlex2 {
    flex: 2 !important;
  }
  
  .th {
    height: 45px;
    background-color: #CCCCCC;
    color: #333333;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex: 1;
    font-size: 20px;
    font-weight: 700;
    font-family: "VodafoneRg-Regular";
    word-break: break-word;
    /* text-align: center; */
  }
  
  .thFlex2 {
    flex: 2 !important;
  }
  .tr:nth-child(even) {
    background-color: #F4F4F4;
  } 
  
  .tr:nth-child(odd) {
    background-color: #FFFFFF;
    color: #333333;
  }

  .invoice{
    display: flex;
    justify-content: start;
  }

  .invoiceheader{
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
  }