.section {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.sectionTitle {
    color: #100045;
    font-family: "VodafoneRg-Regular"
}

.separator {
    display: flex;
    height: 2px;
    border-top: 1px solid #666666;
    align-self: stretch;
    margin-top: 40px;
}

.fieldContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}