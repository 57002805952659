.inactiveUserContainer {
    margin-top: 100px;
    text-align: center;
    color: #000000;
    font-size: 24px;
    font-family: "VodafoneRg-Regular";
}

.headerText {
    font-family: "VodafoneRg-Regular";
    font-size: 56px;
    font-style: normal;
    text-align: center;
    color: #333333;
}

.headerLogo {
    text-align: center;
}

.logo {
    margin-top: 10px;
    height: 40px;
    width: 40px;
    margin-left: 16px;
}
.linkbtn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}