.expanded {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}
.transparentBtn {
  background-color: transparent;
  display: flex;
  gap: 10px;
  border: none;
}

.sideNavStyle {
  width: 50% !important;
  overflow: hidden;
}
.borderLayoutMain {
  border-radius: 6px;
  border: 0.4px solid #999999;
  margin: 0px 20.5px 20.5px 20.5px;
}
.topsection {
  /* display: flex;
  justify-content: space-between; */
  padding: 0 20px 0 30px;
}
.invoiceHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.invoiceHeaderText {
  margin-top: 36px;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  font-size: 30px;
  color: #e60101;
}
.invoiceHeaderLogo {
  display: flex;
  margin-top: 22px;
  width: 33px;
  height: 34px;
}
.sidebarContent {
  display: flex;
  flex-direction: column;
}

.detailContent{
  height: calc(100vh - 124px - 56px); 
  overflow: auto;
}

.sidebarRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.sidebarRowContent {
  display: flex;
  /* font-family: "VodafoneRg-Regular";
  font-weight: 700;
  font-size: 14px;
  color: #333333; */
}

.sidebarRowContentloading{
  display: grid;
  gap: 10px;
  /* margin-bottom: 5px; */
  grid-template-columns: 1fr 1fr;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  font-size: 14px;
  color: #333333;
}

.sidebarRowContent .label {
  margin-right: 5px;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  font-size: 14px;
  color: #333333;
  line-height: 16.07px;
}
.sidebarRowContent .value {
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 16.07px;
}

.divider {
  border: none;
  border-top: 1px solid #666666;
}

.subContent {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-top: 20px;
}

.colContent {
  display: flex;
  flex-direction: column;
  font-family: "VodafoneRg-Regular";
  color: #333333;
}

.colContentloading{
  display: "flow";
  width: 150px;
}
.colContent .label {
  font-weight: 400;
  font-size: 12px;
  color: #333333;
  line-height: 13.78px;
  margin-bottom: 10px;
}

.colContent .value {
  font-weight: 700;
  font-size: 16px;
  color: #333333;
  line-height: 13.78px;
}

.colContentloading .label {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #333333;
  line-height: 13.78px;
}

.colContentloading .value {
  font-weight: 700;
  font-size: 16px;
  color: #333333;
  line-height: 13.78px;
}

.amountSection {
  height: 153px;
  margin-top: 20px;
  background-color: #EBEBEB;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 20px 20px 20px 30px;
  height: auto;
}

.totalamount{
  padding: 10px 0px;
}

.bottompart{
  margin-bottom: 20px;
}

.divider {
  display: flex;
  border-top: 1px solid #666666;
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
}

.amountContent {
  display: flex;
  justify-content: space-between;
}
.amountloading{
  display: grid;
  grid-template-columns: 1fr 2fr;
  font-family: "VodafoneRg-Regular";
  color: #333333;
}

.amountContent .label {
  font-weight: 700;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  color: #333333;
  line-height: 18.34px;
}

.amountloading .label{
  font-weight: 700;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  color: #333333;
  line-height: 18.34px;
}

.amountContent .value {
  font-weight: 700;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  color: #333333;
  line-height: 18.34px;
}

.amountloading .value {
  font-weight: 700;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  color: #333333;
  line-height: 18.34px;
}

.amontmargin{
  margin-bottom: 10px;
  margin-top: 10px;
}

.downloadSection {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-top: 12px;
  margin-left: 20.5px;
  margin-right: 20.5px;
  border: 0.4px solid #999999;
  border-radius: 6px;
  padding: 12px;
  height: auto;
}

.downloadContent {
  display: flex;
  justify-content: space-between;
  font-family: "VodafoneRg-Regular";
  color: #333333;
}

.downloadContent .label {
  font-weight: 400;
  font-size: 16px;
  border-bottom: 1px solid #333333;
  color: #333333;
  /* cursor: pointer; */
}

.downloadContent .fileIcon {
  width: 36px;
  height: 36px;
}

.downloadContent .downloadIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.footerSection {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  bottom: 52px;
}

.footerSection .separator {
  border-color: #666;
}

.footerContent .buttonLabel {
  font-family: "VodafoneRg-Regular";
  color: #333333;
  font-weight: 400;
  font-size: 16px;
  border-bottom: 1px solid #333333;
  color: #333333;
  cursor: pointer;
}
.footer {
  align-items: end;
  justify-content: end;
}

.separator {
  border-color: 1px solid #666666;
}

.closeButton {
  background-color: #e60000;
  color: #ffffff;
  padding: 24px;
  float: right;
  margin-right: 24px;
}


.shimmerWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  /* background: #f6f7f8; */
  background: #f5f7f8;
}

.shimmer {
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%); */
  background: linear-gradient(to right, #e7ebef 0%, #c2c3c6 20%, #b6bcc3 40%, #f6f7f8 100%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.attach{
  display: grid;
  grid-template-columns: 1fr 9fr;
}

.currency{
  margin-right: 3px;
  font-size: 18px;
  font-weight: 600;
}